import React from 'react'
import styled from 'styled-components'
// import { SpinLogo } from '../animations/SpinLogo'
import img from "../../assets/logo.png";

const Footer: React.FC = () => {
  return (
	<MainContainer>
		<Link href={"/"} style={{}}>
			{/* <SpinLogo /> */}
			<img src={img} width="50" height={"50"} style={{cursor:"pointer"}} alt={""}/>
		</Link>
		<FooterContainer>
			<StyledLink href={'/apps'} rel="noreferrer nofollow"> APPS </StyledLink>
			<StyledLink href={'/blogs'} rel="noreferrer nofollow"> Blogs </StyledLink>
			<StyledLink href={'/support'} rel="noreferrer nofollow"> Support </StyledLink>
			<StyledLink href={'/frequently-asked-questions'}> FAQ </StyledLink>
			<StyledLink href={'/terms-of-service'} rel="noreferrer nofollow"> Terms </StyledLink>
			<StyledLink href={'/privacy-policy'} rel="noreferrer nofollow"> Privacy </StyledLink>
		</FooterContainer>
	</MainContainer>
  )
}

const MainContainer = styled.div`
	grid-column: 1;
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	min-height: 50px;
	padding: 20px;
	align-items: center;
	justify-content: center;
	@media (max-width: 900px) {
		align-items: center;
		justify-content: center;
		height: auto;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	// flex: 5;
	color: #ffffff;
	margin: 20px 0 ;
	justify-content: center;
	justify-self: center;
	flex-wrap: wrap;
	text-transform: uppercase;
	z-index: 10;
	@media (max-width: 900px) {
	}
`

const StyledLink = styled.a`
	text-decoration: none;
	color: #000000;
	margin: 5px 20px;
	padding: 7px;
	border-radius: 3px;
	transition: ease 0.5s;
	font-size: 14px;
	font-weight: 700;
	z-index: 10;
	&:hover {
		color: #E14B9F;
		font-weight: 700;
	}
`
const Link = styled.a`
	display: none;
	align-self: flex-start;
	z-index: 10;
	@media (max-width: 900px) {
		align-self: center;
		justify-content: center;
		align-items: center;
	}
`;

export default Footer
