import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "../Button";
import InputComp from "../InputComp";
import { fetchWrapper } from '../../utils/apiHandlers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import LoadingComponent from '../LoadingComponent';
import SmallButton from '../SmallButton';

const ShowLogsComp: React.FC = ({
}) =>{
    const [searchstr, setsearchstr] = useState('');
    const [startdate, setstartdate] = useState<Date>(new Date());
    const [enddate, setenddate] = useState<Date>(new Date());
    const [filelist, setfilelist] = useState<string[] | undefined>([]);
    const [logs, setlogs] = useState('');
    const [count, setcount] = useState(0);
    const [loading, setloading] = useState(false);
    const [ loadingmessage, setloadingmessage ] = useState("")
    
    const loadlogs = async () => {
        setloading(true);
        // openModal("Loading", "", true);
        const splitSearchWords = searchstr.split(",");
        let tempFileText = "";
        const stdt = new Date(startdate);
        let count = 0;
        setcount(count);
        let eddate = new Date(enddate.getFullYear(), enddate.getMonth(), enddate.getDate()+1, 0, 0, 0)
        for (let d = startdate; d < eddate; d.setDate(d.getDate() + 1)) {
            const dstr = d.toISOString().substring(0, 10);
            const body = { filename: "application-" + dstr + ".log" };

            try{

                const fileinfo:any = await fetchWrapper.post("/api/admin/logs/getlogfileInfo", body)
                
                const fileinfostr = fileinfo;

                const newsplits = fileinfostr.split("\n");

                let newstr = "";
                for (let i = 0; i < newsplits.length; i++) {
                    let oneLine = newsplits[i];
                    let onelinearr = oneLine.split('referrer:');
                    if(splitSearchWords.length==0 || splitSearchWords[0]==''){
                        newstr += oneLine + '\n';
                        // newstr += onelinearr[0] + '\n';
                        count++;
                    }else{
                        for (let j = 0; j < splitSearchWords.length; j++) {
                            if (oneLine.toLowerCase().includes(splitSearchWords[j].toLowerCase())) {
                                count++;
                                // newstr += oneLine + '\n';
                                newstr += oneLine + "\n";
                                break;
                            }
                        }
                    }
                }
                tempFileText += newstr;
            }catch(error){
                // openModal("Error", `${error}`, false);
                console.log(`error: ${error}`);
            }
            setcount(count);
            setloading(false);
        }
        setloading(false);
        setlogs(tempFileText)
        setstartdate(stdt)
        // openModal("Done", "", );
    }

    useEffect(() => {
        let dt = new Date();
        setstartdate(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0));
        setenddate(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0))
        fetchWrapper.post("/api/admin/logs/getlogfileList", {}).then((result: any)=>{
            setfilelist(result);
        }).catch((error)=>{
            // console.log(`error ${error}`);
        })
    },[])

    return (
        <MainContainer>
        <Column>
            <Header>Logs</Header>

            <Row style={{width:"100%"}}>

            <Column style={{flex:4}}>

            <div style={{display:'flex', flexDirection:'column', color:'black', margin: "0 10px"}}>
                <Header>Keywords</Header>
                <Extra onClick={()=>setsearchstr("Registration successful")}>Registration successful</Extra>

            </div>
            <InputComp placeholder='keywords' value={searchstr} onChange={(e)=>setsearchstr(e.target.value.toLowerCase())} id={"showlogemail"} />
            <Button text='clear' handleClick={()=>setsearchstr('')}/>
            </Column>
            <Column style={{flex:2}}>
            <Extra>Start Date</Extra>
            <div style={{display:"flex", justifyContent:"center", alignItems:'center', margin:"10px 0"}}>
                <SmallButton text="-" handleClick={()=>setstartdate(new Date(startdate.getTime() - 24*3600000))} width={25}/>
                <DatePicker
                    dateFormat="MM/dd/yy"
                    selected={startdate}
                    onChange={(date: Date | null) =>
                        date?setstartdate(date):setstartdate(new Date())
                    }
                />
                <SmallButton text="+" handleClick={()=>setstartdate(new Date(startdate.getTime() + 24*3600000))} width={25}/>
            </div>
            <Extra>End Date</Extra>
            <div style={{display:"flex", justifyContent:"center", alignItems:'center', margin:"10px 0"}}>
                <SmallButton text="-" handleClick={()=>setenddate(new Date(enddate.getTime() - 24*3600000))} width={25}/>
                <DatePicker
                dateFormat="MM/dd/yy"
                selected={enddate}
                onChange={(date: Date | null) =>
                    date?setenddate(date):setenddate(new Date())
                }
                />
                <SmallButton text="+" handleClick={()=>setenddate(new Date(enddate.getTime() + 24*3600000))} width={25}/>
            </div>
            <Button handleClick={loadlogs} text="Load" />

            <Extra>Count#: {count}</Extra>
            </Column>
            </Row>
            <br/>
            <Row style={{width:"100%", justifyContent:"center"}}>
            {loading?
                <LoadingComponent />
                :<></>
            }
            </Row>
            <LogInfo value={logs} rows={logs.length==0?5:10}/>

        </Column>
        </MainContainer>
    )
}

const MainContainer = styled.div`
  margin: 25px;
  display: flex;
  box-shadow: 0px 8px 8px 0px rgba(50, 20, 50, 0.5);
  border-radius: 3px;
  width: 90%;

`

const Row = styled.div`
user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width:700px){
    flex-direction: column;
  }
`
const Column = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`
const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
`
const Extra = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 1px 0;
`
const LogInfo = styled.textarea`
    margin: 0;
    padding: 0;
    width: 90%;
    line-height: 1.5;
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    flex-wrap: wrap;
    word-break: break;
    line-break: break;
    overflowX: hidden;
    width: 100%;
    border: none;
    min-height: 250px;
    spellCheck: false;
    &:focus{
        outline: none;
    }
`;

export default ShowLogsComp;
