import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import styles from "./Button.module.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface Prop{
    text: string;
    handleClick?:(x?: any)=>void;
    backgroundColor?: string;
    width?:any;
    delay?: any
}

gsap.registerPlugin(ScrollTrigger);

export const PopupButton : React.FC<Prop>=({text, backgroundColor, handleClick, width, delay})=>{
    const boxRef = useRef(null);

    const btnclick = (e:any) => {
        if(handleClick)
        {
            handleClick()

        }
    }

    useEffect(() => {
        gsap.from(boxRef.current, { 
            scale: 0, 
            opacity: 0,
            duration: 0.5,
            ease: "back.out(1.7)",
            scrollTrigger: {
                trigger: boxRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            }
        });
    }, []);
    return(
        <MainContainer ref={boxRef}>
            {/* <div
                ref={boxRef}
                className="w-32 h-32 bg-blue-500 text-white flex justify-center items-center rounded-lg shadow-lg"
            >
                {text}
            </div> */}
            <div className={`${styles.container} ${styles.ripple}`} onClick={btnclick} style={{backgroundColor:backgroundColor?backgroundColor:"#2487D5", width:width?width:"auto"}}>
                {text}
            </div>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // margin: 5px;
`
