import React, {useState, useEffect, useRef, useCallback} from 'react'
import styled from 'styled-components'
import { io } from "socket.io-client";
import Button from '../../components/Button';
import InputComp from '../../components/InputComp';
import { useHistory } from 'react-router-dom';


interface ScreenSize {
  width: number;
  height: number;
}

interface ControlEvent {
  type: string;
  [key: string]: any;
}

const iceServers = [
    { urls: "stun:stun.l.google.com:19302" }, // Google's STUN server
    { urls: "stun:stun1.l.google.com:19302" }, // Backup STUN server
]

interface ThrottledHandlers {
	mouse_move: (e: any) => void;
	mouse_down: (e: any) => void;
	mouse_up: (e: any) => void;
	mouse_wheel: (e: any) => void;
}

const RDViewerApp: React.FC = () => {

	const history = useHistory()
	
	const containerRef = useRef<HTMLDivElement>(null);
	const [screenSize, setScreenSize] = useState<ScreenSize>({ width: 0, height: 0 });
	const [roomId, setRoomId] = useState('');

	const socketRef: any = useRef(null); // 🔹 Store socket connection
	const remoteVideoRef = useRef<any>(null);
	const peerConnection = useRef<any>(null);
	// const peerConnection = useRef<any>(new RTCPeerConnection({iceServers}));
	const dataChannelRef = useRef<any>(null)
	const [sharingCode, setSharingCode] = useState("");
	const [isRemoteUser, setIsRemoteUser] = useState(false);
	const [ message, setmessage ] = useState("")
	const [ remoteconnected, setremoteconnected ] = useState(false)

	const [ screenfocused, setscreenfocused ] = useState(false)


		type ThrottledFunction<T extends any[]> = (...args: T) => void;
	
		function throttle<T extends any[]>(
			func: (...args: T) => void, 
			limit: number): ThrottledFunction<T> {
	
			let lastFunc: ReturnType<typeof setTimeout>;
			let lastRan: number;
			return function(this: unknown, ...args: T) {
				const context = this;
				if (!lastRan) {
					func.apply(context, args);
					lastRan = Date.now();
				} else {
					clearTimeout(lastFunc);
					lastFunc = setTimeout(() => {
						if ((Date.now() - lastRan) >= limit) {
							func.apply(context, args);
							lastRan = Date.now();
						}
					}, limit - (Date.now() - lastRan));
				}
			}
		}
	
		const throttledHandlers: ThrottledHandlers = {
			mouse_move: throttle<[any]>((e) => {
				if (dataChannelRef.current?.readyState === 'open') {
					dataChannelRef.current.send(JSON.stringify(e));
				}
			}, 50),
		  
			mouse_down: throttle<[any]>((e) => {
				if (dataChannelRef.current?.readyState === 'open') {
					dataChannelRef.current.send(JSON.stringify(e));
				}
			}, 100),
		  
			mouse_up: throttle<[any]>((e) => {
				if (dataChannelRef.current?.readyState === 'open') {
					dataChannelRef.current.send(JSON.stringify(e));
				}
			}, 100),
		  
			mouse_wheel: throttle<[any]>((e) => {
				if (dataChannelRef.current?.readyState === 'open') {
					dataChannelRef.current.send(JSON.stringify(e));
				}
			}, 100)
		  };
	
	const calculateRelativePosition = (clientX: number, clientY: number) => {
		const rect = containerRef.current?.getBoundingClientRect();
		
		if (!rect) return { x: 0, y: 0 };
		return {
		  x: (clientX - rect.left) / rect.width,
		  y: (clientY - rect.top) / rect.height
		};
	  };

  // Send control events through DataChannel
	const sendControlEvent = useCallback((event: ControlEvent) => {
		console.log(`sendcontrol event!`)		
		if (dataChannelRef.current?.readyState === 'open') {
			dataChannelRef.current.send(JSON.stringify(event));
		}
	}, []);

  // Mouse event handlers
	const handleMouseMove = useCallback((e: MouseEvent) => {
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		throttledHandlers.mouse_move({
			type: 'mouse_move',
			x,
			y,
			buttons: e.buttons
		});
	}, [calculateRelativePosition, sendControlEvent]);

	const handleclick = useCallback((e: MouseEvent) => {
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		sendControlEvent({
			type: 'mouse_click',
			x,
			y,
			button: e.button,
			buttons: e.buttons
		});

	}, [calculateRelativePosition, sendControlEvent] )

	const handleMouseDown = useCallback((e: MouseEvent) => {
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		throttledHandlers.mouse_down({
			type: 'mouse_down',
			x,
			y,
			button: e.button,
			buttons: e.buttons
		});
	}, [calculateRelativePosition, sendControlEvent]);

	const handleMouseUp = useCallback((e: MouseEvent) => {
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		throttledHandlers.mouse_up({
			type: 'mouse_up',
			x,
			y,
			button: e.button,
			buttons: e.buttons
		});
	}, [calculateRelativePosition, sendControlEvent]);

	const handleDoubleClick = useCallback((e: MouseEvent) => {
		
		console.log(`handle double click`)
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		sendControlEvent({
			type: 'mouse_dblclick',
			x,
			y,
			button: e.button
		});
	}, [calculateRelativePosition, sendControlEvent]);

	// Keyboard event handlers
	const handleKeyDown = useCallback((e: KeyboardEvent) => {
		
		sendControlEvent({
			type: 'key_down',
			key: e.key,
			code: e.code,
			ctrlKey: e.ctrlKey,
			shiftKey: e.shiftKey,
			altKey: e.altKey,
			metaKey: e.metaKey
		});
	}, [sendControlEvent]);

	const handleKeyUp = useCallback((e: KeyboardEvent) => {
		// sendControlEvent({
		// 	type: 'key_up',
		// 	key: e.key,
		// 	code: e.code,
		// 	ctrlKey: e.ctrlKey,
		// 	shiftKey: e.shiftKey,
		// 	altKey: e.altKey,
		// 	metaKey: e.metaKey
		// });
	}, [sendControlEvent]);

	// Wheel event handler
	const handleWheel = useCallback((e: WheelEvent) => {
		e.preventDefault();
    	e.stopPropagation();
		const { x, y } = calculateRelativePosition(e.clientX, e.clientY);
		throttledHandlers.mouse_wheel({
			type: 'wheel',
			x,
			y,
			deltaX: e.deltaX,
			deltaY: e.deltaY,
			deltaZ: e.deltaZ,
			deltaMode: e.deltaMode
		});
	}, [calculateRelativePosition, sendControlEvent]);

  // Connect to host
	const joinSharing = async () => {
		if (!sharingCode) return alert('Enter a valid sharing code!');
		setremoteconnected(true)
		setIsRemoteUser(true);
        peerConnection.current = new RTCPeerConnection({iceServers})
		
		peerConnection.current.ontrack = (event: any) => {
			console.log('Received remote track:', event);
			if (remoteVideoRef.current) {
				remoteVideoRef.current.srcObject = event.streams[0];
			}
		};

		peerConnection.current.ondatachannel = (event: any) => {
			console.log("Received DataChannel from host");
			dataChannelRef.current = event.channel;
		
			dataChannelRef.current.onopen = () => {
				console.log("DataChannel is open!");
				setupInputHandling()
				console.log(`input handling is set!`)
				
			};
		
			dataChannelRef.current.onmessage = (event: any) => {
				console.log("Received message:", event.data);
			};
		};

		peerConnection.current.onicecandidate = (event: any) => {
			if (event.candidate) {
				console.log('Sending ICE candidate:', event.candidate);
				socketRef.current.emit('candidate', { candidate: event.candidate, room: sharingCode });
			}
		};

		peerConnection.current.oniceconnectionstatechange = () => {
			console.log("ICE connection state:", peerConnection.current.iceConnectionState);
		};

		peerConnection.current.ontrack = (event: any) => {
			console.log("New track received", event.streams);
			remoteVideoRef.current.srcObject = event.streams[0];
		};

		console.log(`socket endpoint : ${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`)
		
		if (!socketRef.current) {
			socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`);
		}
		socketRef.current.on('offer', handleOffer);
		socketRef.current.on('candidate', handleCandidate);
		socketRef.current.on('end-sharing', handlecloseconnection);
		socketRef.current.emit('join-room', sharingCode);
	};

	const handleOffer = async ( data: any ) => {
        console.log('Received Offer form room:', data.room);
        setIsRemoteUser(true);
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data.offer));
        const answer = await peerConnection.current.createAnswer();
        console.log('Sending Answer:', answer);
        await peerConnection.current.setLocalDescription(answer);
        socketRef.current.emit('answer', { answer: answer, room: data.room });
    };

    const handleCandidate = async ( data: any ) => {
        console.log('Received ICE Candidate:', data.candidate);
        await peerConnection.current.addIceCandidate(new RTCIceCandidate(data.candidate));
    };

	const handlecloseconnection = async (data: any) => {
		console.log(`host connection closed!: ${JSON.stringify(data)}`)
		if(data.room==sharingCode){
			console.log(`host connection closed!`)
			setremoteconnected(false)
			socketRef.current.disconnect()
			socketRef.current = null
			setSharingCode("")
			peerConnection.current.close()
			cleanupInputHandling()

			// peerConnection.current = new RTCPeerConnection({iceServers})
		}
	}

  // Set up input event listeners
	const setupInputHandling = useCallback(() => {
		const container = containerRef.current;
		if (!container) return;

		console.log(`setting up input handling`)
		
		
		container.addEventListener('click', handleclick);
		container.addEventListener('mousemove', handleMouseMove);
		container.addEventListener('mousedown', handleMouseDown);
		container.addEventListener('mouseup', handleMouseUp);
		container.addEventListener('dblclick', handleDoubleClick);
		container.addEventListener('keydown', handleKeyDown);
		container.addEventListener('keyup', handleKeyUp);
		container.addEventListener('wheel', handleWheel);
		
		container.tabIndex = 0;
		container.focus();
	}, [
		handleMouseMove,
		handleMouseDown,
		handleMouseUp,
		handleDoubleClick,
		handleKeyDown,
		handleKeyUp,
		handleWheel
	]);

	const closeremoteconnection = () => {
		setremoteconnected(false)
		socketRef.current.disconnect()
		socketRef.current = null
		// setSharingCode("")
		peerConnection.current.close()
		cleanupInputHandling()
    }

  // Clean up input event listeners
	const cleanupInputHandling = useCallback(() => {
		const container = containerRef.current;
		if (!container) return;
		
		container.removeEventListener('mousemove', handleMouseMove);
		container.removeEventListener('mousedown', handleMouseDown);
		container.removeEventListener('mouseup', handleMouseUp);
		container.removeEventListener('dblclick', handleDoubleClick);
		container.removeEventListener('keydown', handleKeyDown);
		container.removeEventListener('keyup', handleKeyUp);
		container.removeEventListener('wheel', handleWheel);
	}, [
		handleMouseMove,
		handleMouseDown,
		handleMouseUp,
		handleDoubleClick,
		handleKeyDown,
		handleKeyUp,
		handleWheel
	]);

	useEffect(()=>{
		let id = history.location.search.split("=")[1]
		if(id){
			console.log(`setting host id from url: ${id}`)
			setSharingCode(id)
		}
	}, [])

  // Clean up on unmount
	useEffect(() => {
		return () => {
		cleanupInputHandling();
		if (peerConnection.current) {
			// peerConnection.current.destroy();
		}
		};
	}, [peerConnection.current, cleanupInputHandling]);

	return (
		<MainContainer onClick={()=>setscreenfocused(false)}>
			<InputComp
				id="remotesharing_code"
				type="text"
				placeholder="Enter remote desktop code"
				value={sharingCode}
				onChange={(e) => setSharingCode(e.target.value)}
			/>
			{remoteconnected?
				<Button handleClick={closeremoteconnection} text="Disconnect" />
				:
				<Button handleClick={joinSharing} text="Connect" />
			}

			{remoteconnected ? (
				
				<VideoContainer
					ref={containerRef}
					style={{
						aspectRatio: screenSize.width && screenSize.height 
						? `${screenSize.width}/${screenSize.height}`
						: '16/9'
					}}
						// onMouseMove={handleMouseMove}
						// onKeyDown={handleKeyDown}
						// onKeyUp={handleKeyUp}
						// onMouseDown={handleMouseClick}
						id="remote-screen"
						onClick={()=>{setscreenfocused(true)}}
					>
						<video ref={remoteVideoRef} autoPlay playsInline style={{ 
							width: "100%",
							height: "100%",
							objectFit: 'contain',
							backgroundColor: "black" 
						}} />
						{/* <RemoteControl videoRef={remoteVideoRef} socket={socketRef.current}/> */}
					</VideoContainer>
			) : <></>}

		</MainContainer>
	);
};

const VideoContainer = styled.div`
	// width: 100%;
	// margin: 20px auto;
	background: #000;
	position: relative;
	overflow: hidden;  /* Prevent scrollbars */
	touch-action: none; /* Disable touch scrolling */
	// cursor: none; /* Hide default cursor */

	/* Disable all browser default behaviors */
	-webkit-overflow-scrolling: touch;
	overscroll-behavior: none;
	z-index: 100;
	pointer-events: all;
	&:focus {
		cursor: none;
	}
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const ButtonContainer = styled.div`
    display: flex;
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
    display: flex;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;

export default RDViewerApp;