import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import gsap from 'gsap';

interface Prop{
    text: string;
    delay: any;
    xfrom?: any;
    yfrom?: any
    duration: any;
}
export const AnimateText : React.FC<Prop>=({text, delay, xfrom, yfrom, duration})=>{
    const boxRef = useRef(null);

    useEffect(() => {
        gsap.from(boxRef.current, { rotation:10, x: xfrom, y: yfrom, opacity: 0, duration: 1, ease: "power2.out", delay: delay });
      }, []);
    return(
        <MainContainer ref={boxRef}>
            {text}
        </MainContainer>
    );
}


const MainContainer = styled.div`
`
