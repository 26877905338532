import React, {useState, useEffect, useRef} from 'react'
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { PopupButton } from '../../components/animations/PopupButton';
import gsap from 'gsap'
import ZappImage from "../../assets/zappletz.png"

const blogs = [
	{
		title: "Password Manager",
		url: "/password-manager",
		info: [
			"Safe and Secure",
			"Access anywhere",
			"Generate strong passwords",
			"Save 100 passwords free",
			"Backup your passwords",
			"Registration required"
		]
	},
	{
		title: "PDF Manager",
		url: "/apps/pdf-app",
		info: [
			"Edit, Sign, Merge and Split documents"
		],
	},
	{
		title: "Image Editor",
		url: "/apps/image-app",
		info: [
			"Resize, Crop images",
			"Convert image into jpg or png ",
			"Make printable passport images ",
			"Add captions to your image",
			"Create favicons"
		],
	},
  {
		title: "Text 2 Speech",
		url: "/apps/tts-app",
		info: [
			"High-quality voices",
			"Adjustable speech speed",
			"Save audio files in MP3 format",
			"Playback and download!",

		],
	},
  {
		title: "Video Editor",
		url: "/apps/video-app",
		info: [
			"Trim, cut, merge, and split videos",
			"Create slow motion",
			"Add background music",
			"Add text, captions, and animated titles",
		],
	},
  {
		title: "P2P Meeting",
		url: "/apps/meet-app",
		info: [],
	},
];

interface Prop{
}
const Apps : React.FC<Prop>=({})=>{
    const history = useHistory()
	const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(()=>{
		// gsap.from(cardsRef.current, {
		// 	opacity: 0,
		// 	y: 50,
		// 	duration: 0.6,
		// 	stagger: 0.2,
		// 	ease: "power2.out",
		// 	scrollTrigger: {
		// 		trigger: cardsRef.current,
		// 		start: "top 80%",
		// 		toggleActions: "play none none none"
		// 	}
		// })
	})

  return(
    <MainContainer>
		{/* <Navbar /> */}
		<Content>
			<Headerdiv >
				<Header>APPS by</Header>
				<Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
				{/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}

			</Headerdiv>
			{blogs.map((value: any, indx: any) => (
			<Card  ref={(el: any) => { if (el) cardsRef.current[indx] = el; }} key={`card_${indx}_apps}`} >
				<SubHeader>{value.title}</SubHeader>
				{value?.info.map((a: any, b: any)=>(
					<LI key={`sublist_${indx}_${b}_apps}`}>
						{a}
					</LI>
				))}
				<BtnDiv>
					{indx!==-1?
						<PopupButton text={`${value.title}`} handleClick={()=>history.push(value.url)} />
						:
						<Button text={`${value.title}`} handleClick={()=>history.push(value.url)} />
					}
					</BtnDiv>
			</Card>
			))}

		</Content>
		{/* <Footer /> */}
    </MainContainer>
  );
}

const MainContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`
const Content = styled.div`
	grid-column: 1fr;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	padding: 2.5em;
	@media(max-width: 900px){
		flex-direction: column;
	}
`;

const Headerdiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: min(75%, 350px);
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	width: min(75%, 350px);
	height: auto;
	margin: 1.25rem;
	padding: 1rem;
	align-items: center;
	border-radius: 1.2em;
	// background: linear-gradient(180deg, rgba(75, 159, 225, 0.25) 75%, rgba(255,255,255,0.5) 100%);
	background: white;
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
	box-shadow: 0 0 10px rgba(75, 84, 225, 0.25);
`;

const BtnDiv = styled.div`
	display: flex;
	justify-self: flex-end;
`;



const LI = styled.div`
	font-size: 1.05rem;
	padding: 0.5em;
	cursor: pointer;
	color: #FFD700;
	color: #E14B9F;
	// text-align: center;
	text-decoration: none;
	font-weight: 700;
	width: 100%;
	&:hover{
// 		// margin: 0.6rem;
		font-size: 16px;
	}
`;


const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;

const SubHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	font-size: 1.75rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	justify-self: center;
	justify-content: center;	
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const Image = styled.img`
	width: min(350px, 75%);
`;


// const LI = styled.div`
// 	font-size: 1.25rem;
// 	padding: 0.5em;
// 	width: 100%;
// 	color: yellow;
// 	@media(max-width: 900px){
// 		font-size: 16px;
// 		// margin: 0.6rem 0.6rem;
// 	}
// `;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default Apps