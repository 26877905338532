import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components';
import { RiArrowDownSLine } from 'react-icons/ri';
import { RiArrowRightSLine } from 'react-icons/ri';
import ZappImage from "../assets/zappletz.png"

interface Prop{
    history?: any;
    q?: string;
    a?: any;
}

const QuestionComp:React.FC<Prop> = ({q, a}) => {
    const [show, setshow] = useState(false);
    return(
        <Column style={{padding: "10px 0"}}>
        <QuestionContainer onClick={()=>setshow(!show)}>
            {
            show?
                <ArrowDown>
                    <RiArrowRightSLine fontSize={"22px"} color={"#E14B9F"}/>
                </ArrowDown>
                :
                <ArrowRight>
                    <RiArrowRightSLine fontSize={"22px"} color={"#E14B9F"}/>
                </ArrowRight>
            }
            <Question>{q}</Question>
        </QuestionContainer>
        {
            show?
        <QuestionContainer>
            <Answer>
                {a}
            </Answer>
        </QuestionContainer>
        :<></>
        }

        </Column>
    )
}


const FAQs : React.FC<Prop>=({})=>{



    const getSublevels = () => {
        return (
            <>
                There are 3 levels of subscription<ul><li>Basic: Free</li><li>Preferred: $10/month</li><li>Premium: $20/month</li></ul>
            </>
        )
    }

  return(
    <MainContainer>
        <ContentContainer>
        <ColumnX style={{ alignSelf: "flex-start", alignItems: "center"}}>
        <Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
            {/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}
            <Caption>frequently asked questions</Caption>
        </ColumnX>
        <Column style={{ alignItems: "center"}}>
            <SubHeader>Frequently Asked Questions</SubHeader>
            <QuestionComp q='How do I register an account on zappletz.com?' a={"To register visit http://zappletz.com/register"} />
            <QuestionComp q='How do I save information on zappletz.com?' a={"To save information, click on save menu on the Dashboard. Enter the Label for the information, associated username or email and the password."} />
            <QuestionComp q={"What is a 'Passphrase' and why is it important?"} a={`All information is encrypted and saved on our database. Only your 'Passphrase' can decode that information. It is not stored anywhere, ensuring your data's security. The "Passphrase" is the only information you need to remember to access your saved data.`} />
            <QuestionComp q='What happens if I forget or lose my passphrase?' a={"If you forget or lose your passphrase, all your stored information will be lost and cannot be recovered."} />
            <QuestionComp q='Can I reset my passphrase?' a={"Yes, you can reset your passphrase by generating new encryption keys. However, this will erase all previously saved information."} />
            <QuestionComp q='How do I retrieve passwords on zappletz.com?' a={"To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter."} />
        </Column>
        </ContentContainer>
    </MainContainer>
  );
}

const showAnimation = keyframes`
0% {
	opacity: 0
}
50% {
	opacity: 0.5
}
100% {
	opacity: 1;
}
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}`;

const MainContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`

const ContentContainer = styled.div`
	grid-column: 1fr;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	@media(max-width: 900px){
		grid-template-columns: repeat(1, 1fr);
	}
`;
const ColumnX = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10.5em 0.6em 0.6em 0.6em;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
    @media(max-width: 900px){
	    padding: 20px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
`;

const Row = styled.div`
	user-select: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const InfoRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 5px;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;

const QuestionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #ffffff;
    animation: ${showAnimation} 0.5s linear;
`;

const Question = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #000;
    cursor: pointer;
`;

const ArrowRight =styled.div`
	width: 20px;
`;

const ArrowDown =styled.div`
    width: 20px;
    opacity: 1;
    transform: rotate(90deg);
    animation: ${spin} 0.25s linear;
`;
const Answer = styled.div`
    margin: 10px 0 0 0;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 15px;
    justify-content: flex-end;
    line-height: 2;
    color: #E14B9F;
`;

const OrderedList = styled.ol`
    margin: 10px 0 0 0;
    padding: 0px 20px;
`;

const List = styled.li`
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #ffffff;
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default FAQs