import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import Button from "../components/Button";
import { userContext } from '../usercontext';
import Profile from "../components/Profile";
import Encrypt from "../components/Encrypt";
import Decrypt from "../components/Decrypt";
import Billing from "../components/Billing";
import LoadingComponent from "../components/LoadingComponent";
import BackupData from "../components/BackupData";
import { useHistory } from "react-router-dom";

interface Props{

}

const Dashboard: React.FC<Props> = (props) => {

  const history = useHistory()
  const [ user, setUser ] = useState<any>("")
  const [ showitem, setshowitem ] = useState("2")
  

  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const showComp = () => {
      switch(showitem){
      // case "0":
      //   return <Profile />
      case "1":
        return <Encrypt />
      case "2":
        return <Decrypt />  
      case "3":
        return <Billing />
      case "4":
        return <BackupData />
      default:
        return <></>
    }
  }


  useEffect(() => {
    
  }, [showitem])

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    if(!user)return;
    if(user.status!==200){
      history.push("/login")
    }
  })


  return (
    <>
      <MainContainer style={{flexDirection: 'column'}}>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
          {/* <Header>Dashboard</Header> */}
          <Row>
          {user&&user.status==200?<>

            <Column>
			<SubHeader>Password Manager</SubHeader>
				<Row>
					<Button text="Retrieve" handleClick={()=>setshowitem("2")} backgroundColor={showitem=="2"?"#E14B9F":""} width="75px"/>
					<Button text="Save" handleClick={()=>setshowitem("1")} backgroundColor={showitem=="1"?"#E14B9F":""} width="75px"/>
					<Button text="Backup" handleClick={()=>setshowitem("4")} backgroundColor={showitem=="4"?"#E14B9F":""} width="75px"/>
				</Row>

              {
                showComp()
              }
            </Column>
            </>:
            <>
              <LoadingComponent />  
            </>
          }
        </Row>
      </MainContainer>
    </>
  );
};


const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 85vh;
	align-items: center;
`

const Row = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;
  margin: 0.6rem;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  @media (max-width: 770px) {
    padding: 10px;
  }
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;


export default Dashboard;
