import React, {useState, useEffect, useRef} from 'react'
import styled, { keyframes } from 'styled-components'
import { io } from "socket.io-client";
import Button from '../../components/Button';
import InputComp from '../../components/InputComp';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../../utils/helperfunctions';
import { fetchWrapper } from '../../utils/apiHandlers';

interface Prop{

}

const iceServers = [
    { urls: "stun:stun.l.google.com:19302" }, // Google's STUN server
    { urls: "stun:stun1.l.google.com:19302" }, // Backup STUN server
]

const RemoteDesktopApp : React.FC<Prop>=({})=>{

    const history = useHistory()
    const [ friendemail, setfriendemail ] = useState("")

    const socketRef: any = useRef(null); // 🔹 Store socket connection
    const remoteVideoRef = useRef<any>(null);
    const peerConnection = useRef<any>(new RTCPeerConnection({iceServers}));
    const dataChannelRef = useRef<any>(null)
    const [sharingCode, setSharingCode] = useState("");
    const [isRemoteUser, setIsRemoteUser] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const screenStream = useRef<any>(null); // Store screen stream

    const [ modalstate, setmodalstate ] = useState(false)
    const [ modalheader, setmodalheader ] = useState("")
    const [ modalmessage, setmodalmessage ] = useState("")
    const [ modalprogress, setmodalprogress ] = useState(false)
    const [ remoteconnected, setremoteconnected ] = useState(false)
    

    const openModal = (h: any, m: any, p?: any)=>{
        setmodalheader(h)
        setmodalmessage(m)
        setmodalprogress(p?true: false)
        setmodalstate(true)
    }

    const startSharing = async () => {
        try {

            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
            screenStream.current = stream;
            
            stream.getTracks().forEach((track) => {
                console.log('Adding track:', track);
                peerConnection.current.addTrack(track, stream);
            });

            const newCallId = Math.random().toString(36).substring(2, 15);
            console.log(`socket endpoint : ${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`)
            
            socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`);

            setSharingCode(newCallId);
            console.log(`emiting create-room: ${newCallId}`)

            peerConnection.current.onicecandidate = (event: any) => {
                if (event.candidate) {
                    console.log('Sending ICE candidate:', event.candidate);
                    socketRef.current.emit('candidate', { candidate: event.candidate, room: newCallId });
                }
            };

            dataChannelRef.current = peerConnection.current.createDataChannel("inputEvents", {
                ordered: true,       // Ensure event ordering
                reliable: true,
                // maxRetransmits: 3,   // Balance between reliability and latency
                maxPacketLifeTime: 1000 // For unreliable mode
              });

            dataChannelRef.current.onopen = handleremotedatachannelopen
            dataChannelRef.current.onclose = handleremotedatachannelclose

            dataChannelRef.current.onmessage = (event: any) => {                    
                const inputEvent = JSON.parse(event.data);
                handleRemoteInput(inputEvent);
            };
            
            socketRef.current.on('room-joined', handleroomjoined)
            socketRef.current.on('answer', handleAnswer);
            socketRef.current.on('candidate', handleCandidate);
            // socketRef.current.on('mouse-move', handleRemoteMouseMove);
            // socketRef.current.on('key-press', handleRemoteKeyPress);
            socketRef.current.emit('create-room', newCallId);

            setIsSharing(true);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

    const handleRemoteInput = async (event: any) => {
        // const targetElement = document.getElementById("remote-screen"); // Example target
        // console.log(`remote input received: ${JSON.stringify(event)}`)
        
        // if (!targetElement) return;
        if (event.type === "mouseMove") {
            const remoteEvent = new MouseEvent("mousemove", {
                clientX: event.x,
                clientY: event.y,
                bubbles: true
            });
            document.dispatchEvent(remoteEvent);
        } else if (event.type === "mouseClick") {
            const remoteEvent = new MouseEvent("click", {
                button: event.button,
                bubbles: true
            });
            document.dispatchEvent(remoteEvent);
        } else if (event.type === "keyDown") {
            const remoteEvent = new KeyboardEvent("keydown", {
                key: event.key,
                bubbles: true
            });
            document.dispatchEvent(remoteEvent);
        } else if (event.type === "keyUp") {
            const remoteEvent = new KeyboardEvent("keyup", {
                key: event.key,
                bubbles: true
                });
            document.dispatchEvent(remoteEvent);
        }
    }


    const stopSharing = () => {
        screenStream.current?.getTracks().forEach((track: any) => track.stop());
        setIsSharing(false);
        setSharingCode('');
        // socketRef.current.emit('disconnect');
        socketRef.current.disconnect();
    };

    const handleremotedatachannelopen = (data: any)=>{
        console.log(`remote datachannel is open: ${JSON.stringify(data)}`)
    }

    const handleremotedatachannelclose = (data: any)=>{
        console.log(`remote datachannel is closed: ${JSON.stringify(data)}`)
    }

    const joinSharing = async () => {
        if (!sharingCode) return alert('Enter a valid sharing code!');
        setremoteconnected(true)
        setIsRemoteUser(true);

        
        peerConnection.current.ontrack = (event: any) => {
            console.log('Received remote track:', event);
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = event.streams[0];
            }
        };

        peerConnection.current.ondatachannel = (event: any) => {
            console.log("Received DataChannel from host");
            dataChannelRef.current = event.channel;
        
            dataChannelRef.current.onopen = () => {
                console.log("DataChannel is open!");
            };
        
            dataChannelRef.current.onmessage = (event: any) => {
                console.log("Received message:", event.data);
            };
        };

        peerConnection.current.onicecandidate = (event: any) => {
            if (event.candidate) {
                console.log('Sending ICE candidate:', event.candidate);
                socketRef.current.emit('candidate', { candidate: event.candidate, room: sharingCode });
            }
        };

        peerConnection.current.oniceconnectionstatechange = () => {
            console.log("ICE connection state:", peerConnection.current.iceConnectionState);
        };

        peerConnection.current.ontrack = (event: any) => {
            console.log("New track received", event.streams);
            remoteVideoRef.current.srcObject = event.streams[0];
        };

        console.log(`socket endpoint : ${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`)
        
        if (!socketRef.current) {
            socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`);
        }
        socketRef.current.on('offer', handleOffer);
        socketRef.current.on('candidate', handleCandidate);
        socketRef.current.emit('join-room', sharingCode);
    };

    const handleroomjoined = async (data: any) => {
        console.log(`room-joined msg received!: ${JSON.stringify(data.room)}`)
        const offer = await peerConnection.current.createOffer();
        await peerConnection.current.setLocalDescription(offer);
        console.log('Sending Offer to room: ', data.room);
        socketRef.current.emit('offer', { offer: offer, room: data.room });
    }

    const handleOffer = async ( data: any ) => {
        console.log('Received Offer form room:', data.room);
        setIsRemoteUser(true);
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data.offer));
        const answer = await peerConnection.current.createAnswer();
        console.log('Sending Answer:', answer);
        await peerConnection.current.setLocalDescription(answer);
        socketRef.current.emit('answer', { answer: answer, room: data.room });
    };

    const handleAnswer = async ( data: any ) => {
        console.log('Received Answer:', data.answer);
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data.answer));
        socketRef.current.emit("answer-processed", {room: data.room})
    };

    const handleCandidate = async ( data: any ) => {
        console.log('Received ICE Candidate:', data.candidate);
        await peerConnection.current.addIceCandidate(new RTCIceCandidate(data.candidate));
    };

    const sharecode = async () => {
        if(!validateEmail(friendemail))
        {
            openModal("Warning", "Invalid email")
            return
        }
        await fetchWrapper.post("/api/invitefriend/sharerdcode", {friendemail:friendemail, callid: sharingCode})
        openModal("Message", `code shared with ${friendemail}`, false)
    }

    const sendInputEvent = (data: any) => {
        // console.log(`datachannelRef: ${JSON.stringify(dataChannelRef)}`)
        if(!dataChannelRef || !dataChannelRef.current)return
        // console.log(`datachanel state: ${dataChannelRef.current.readyState}`)       
        
        if (dataChannelRef.current.readyState === "open") {
            dataChannelRef.current.send(JSON.stringify(data));
        }
    }

    function handleMouseMove(event: any) {
        sendInputEvent({ type: "mouseMove", x: event.clientX, y: event.clientY })
        // socketRef.current.emit("mouse-move", { x, y });
    }

    function handleKeyDown(event: any) {
        console.log(`key down event`)   
        sendInputEvent({ type: "key-down", key: event.key })
        // socketRef.current.emit("key-press", event.key);
    }

    function handleKeyUp(event: any) {
        console.log(`key up event`)        
        sendInputEvent({ type: "key-up", key: event.key })
        // socketRef.current.emit("key-press", event.key);
    }


    function handleMouseClick(event: any) {
        console.log(`mouse clicked`)        
        sendInputEvent({ type: "click", button: event.button })
        // socketRef.current.emit("key-press", event.key);
    }


    const closeremoteconnection = () => {
        socketRef.current.disconnect()
    }

    useEffect(()=>{
        let id = history.location.search.split("=")[1]
        if(id){
            console.log(`setting host id from url: ${id}`)
            setSharingCode(id)
        }
    }, [])

    useEffect(() => {
        if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
            console.log(`remoteVideoRef.current : ${JSON.stringify(remoteVideoRef.current)}`)
            
            remoteVideoRef.current.play().catch(console.error);
        }
    }, [remoteVideoRef.current?.srcObject]);

    useEffect(() => {
        
    }, [sharingCode]);

    useEffect(() => {
        return () => {
            if(socketRef.current){
                socketRef.current.off('offer', handleOffer);
                socketRef.current.off('answer', handleAnswer);
                socketRef.current.off('candidate', handleCandidate);
                socketRef.current.disconnect();
            }
        };
    }, []);

    return(
        <MainContainer>
            {!remoteconnected?<>
            <Header>Remote Desktop APP</Header>
            <Column>
            <Row style={{color:'red'}}>
                    {modalmessage}
            </Row>
            {!isSharing ? (
                    
                <Button handleClick={startSharing} text="Start Sharing" />
            ) : (
                <>
                <InputComp id="call id" placeholder="share this call id" value={sharingCode} onChange={()=>{}} />
                <InputComp id="email" placeholder="participant email" value={friendemail} onChange={(e)=>setfriendemail(e.target.value)} />
                <ButtonContainer>
                    <Button handleClick={stopSharing} text="End Sharing" />
                    <Button text="Email Code" handleClick={sharecode} />
                </ButtonContainer>
                </>
            )}

            {!isSharing && (
                <>
                    <InputComp
                        id="remotesharing_code"
                        type="text"
                        placeholder="Enter remote desktop code"
                        value={sharingCode}
                        onChange={(e) => setSharingCode(e.target.value)}
                    />
                    {remoteconnected?
                    <Button handleClick={closeremoteconnection} text="Disconnect" />
                    :
                    <Button handleClick={joinSharing} text="Connect" />
                    }
                </>
            )}
            </Column>
            </>:
            <>
                {isRemoteUser ? (
                    <VideoContainer
                        onMouseMove={handleMouseMove}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        onMouseDown={handleMouseClick}
                        id="remote-screen"
                    >
                        <video ref={remoteVideoRef} autoPlay playsInline style={{ width: "100%", height: "80vh", backgroundColor: "black" }} />
                        {/* <RemoteControl videoRef={remoteVideoRef} socket={socketRef.current}/> */}
                    </VideoContainer>
                ) : (
                    isSharing && (
                        <p className="text-yellow-400 text-lg mt-4">
                            Your screen is being controlled remotely.
                        </p>
                    )
                )}
            </>
        }
        </MainContainer>
    );
}

const VideoContainer = styled.div`

`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const ButtonContainer = styled.div`
    display: flex;
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
    display: flex;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;
export default RemoteDesktopApp