import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from '../Button'
import Input from "../InputComp";
import Textarea from "../TextboxComp";
import { clientLogger } from "../../utils/clientLogger";
import { validateEmail } from "../../utils/helperfunctions";
import { userContext } from '../../usercontext';
import { fetchWrapper } from "../../utils/apiHandlers";
import MessageModal from "../MessageModal";

interface dataI {
}

type Props = dataI;

function ContactComponent(props: Props) {
    const [user, setuser] = useState<any>(null)
    const [email, setEmail] = useState("")
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneValid, setPhoneValid] = useState(false)
    // const [propertyAddress, setPropertyAddress] = useState("")
    const [requestMessage, setRequestMessage] = useState("")
    const [message, setMessage] = useState("")
    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)


    const openModal = (header: string, message: string, progress?: boolean) => {
        setmodalheader(header)
        setmodalmessage(message)
        setmodalstate(true)
        setmodalprogress(progress?progress: false)
      }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value)
    };

    const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;
        let newTxt = e.target.value.replace(/-/g, "")
        let phone = "";
        if (newTxt === "" || re.test(newTxt)) {
        phone = formatInput(newTxt, [4, 8])
        setPhone(phone)
        }
        if (phone.length === 12) {
        setPhoneValid(true)
        } else {
        setPhoneValid(false)
        }
    };

    const formatInput = (newTxt: string, dashPos: number[]) => {
        newTxt = newTxt.replace(/-/g, "")
        dashPos.forEach((value, indx) => {
        if (newTxt.length >= dashPos[indx]) {
            newTxt =
            newTxt.slice(0, dashPos[indx] - 1) +
            "-" +
            newTxt.slice(dashPos[indx] - 1)
        }
        })
        return newTxt;
    };

    const handleFirstNameChange = (e: any) => {
        setFirstName(e.target.value)
    };

    const handleLastNameChange = (e: any) => {
        setLastName(e.target.value)
    };

    const handlerequestMessageChange = (e: any) => {
        setRequestMessage(e.target.value)
    };

    useEffect(() => {
        const subscription = userContext.user.subscribe((x:any) => setuser(x))
        return () => subscription.unsubscribe()
    }, [])

    useEffect(()=>{
        if(user && user.status==200){
            setEmail(user.email)
            setFirstName(user.firstname)
            setLastName(user.lastname)
            setPhone(user.phonenumber)
            setPhoneValid(true)
        }
      },[user])


    const sendMessage = () => {

        if (!validateEmail(email)) {
        openModal("Warning","Please enter a valid email",)
        setEmail("")
        return;
        }
        if (firstname == "") {
        openModal("Warning","Please enter your first name.",)
        return;
        }
        if (lastname == "") {
        openModal("Warning","Please enter your last name.",)
        return;
        }
        if (!phoneValid) {
            openModal("Warning","Please enter a valid phone",)
            return;
        }
        if (requestMessage.length<10) {
            openModal("Warning","Your message is too short.")
            return;
        }

        const body = {
            email: `support@zerorets.com`,
            clientemail: email,
            phone: phone,
            firstname: firstname,
            lastname: lastname,
            message: requestMessage,
        }

        fetchWrapper
        .post("/api/requestinformation", body)
        .then((response) => {
            openModal("Request Sent","Thank you for your request. A ZappletZ agent will contact you with you inquiry soon.")
            setRequestMessage("")
        })
        .catch((err) => {
            console.log("error: ", err)
            openModal("Error","Failed to send message please try again.")
        })
    };

    return (
        
        <>
        <MessageModal 
            header={modalheader} message={modalmessage} progress={modalprogress} state={modalstate} handleClose={()=>setmodalstate(false)}
        />
        {/* <MainContainer style={{display:'flex', flexDirection:'column', alignItems:'center', width: "100%", justifyContent:'center', padding:"0 100px"}}> */}
        
        <Card>
            <Header>Support</Header>

            <BoldText2>Email: support@ZappletZ.com</BoldText2>
            {/* <BoldText2>Phone / Text: (469) 299-0012</BoldText2> */}
            <Input id="input0"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
            />

            <Input id="input1"
                placeholder="First Name"
                value={firstname}
                onChange={handleFirstNameChange}
            />

            <Input id="input2"
                placeholder="Last Name"
                value={lastname}
                onChange={handleLastNameChange}
            />

            <Input id="input3"
                placeholder="Phone"
                value={phone}
                onChange={changePhoneNumber}
            />
            <Textarea
                id="txtarea1"
                rows={7}
                cols={50}
                placeholder="Enter your message here"
                value={requestMessage}
                onChange={handlerequestMessageChange}
                width={250}
            ></Textarea>
            <Button text='Send Message' handleClick={sendMessage}/>
        {/* </MainContainer> */}
        </Card>
        </>
    )
}

const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;

const BoldText = styled.div`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 5px;
`;

const BoldText2 = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
`;
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	width: min(90%, 450px);
	height: auto;
	margin: 3.5rem;
	padding: 1.75rem;
	align-items: center;
	border-radius: 1.2em;
	// background: linear-gradient(180deg, rgba(126, 178, 241, 0.5) 25%, rgba(255,255,255,1)  100%);
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
    // background: linear-gradient(137deg, rgba(255,255,255,1) 50%, rgba(225,75,159,1) 100%);
	box-shadow: 0 0 4px rgba(0,0,0, 0.125);
    &:hover{
	    box-shadow: 0 0 40px rgba(0,0,0, 0.5);
        transition: 0.5s ease;
    }
        @media(max-width: 900px){
            padding: 1.75em 3.5em;
        }
`;

export default ContactComponent;
