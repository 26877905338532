import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Home from "./pages/index";
import Login from "./pages/login";
import Register from "./pages/register";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import FAQs from "./pages/frequently-asked-questions";
import Dashboard from "./pages/dashboard"
import Terms from "./pages/terms-of-service";
import PrivacyPolicy from "./pages/privacy-policy";
import UnsubscribePage from "./pages/unsubscribe";
import Pagenotfound from "./pages/404";
import Blog from "./pages/blogs/index";
import PWDBlog from "./pages/blogs/password-manager-blog";
import Support from "./pages/support";
import StrongPwd from "./pages/blogs/how-strong-is-your-password"
import Admin from "./pages/admin/zappadmin";
import Apps from "./pages/apps";
import DocumentEditor from "./pages/apps/document-editor";
import Profile from "./pages/profile";
import PhotoEditor from "./pages/apps/photo-editor";
import MeetApp from "./pages/apps/meet-app"
import TTSApp from "./pages/apps/ztts-app"
import ForgotPassword from "./pages/forgot-password";
import RemoteDesktopApp from "./pages/apps/remote-desktop";
import RDHost from "./pages/apps/rdhost"
import RDViewer from "./pages/apps/rdviewer"

function App() {
  // const location = useLocation();
  // const hideNavAndFooter = location.pathname === '/sitemap.xml';
  return (
      <Router>
        <NavBar />
        <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/frequently-asked-questions" component={FAQs} />
        <Route exact path="/password-manager" component={Dashboard} />
        <Route exact path="/terms-of-service" component={Terms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/unsubscribe" component={UnsubscribePage} />
        <Route exact path="/blogs" component={Blog} />
        <Route exact path="/blogs/why-password-manager" component={PWDBlog} />
        <Route exact path="/blogs/how-strong-is-your-password" component={StrongPwd} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/404" component={Pagenotfound} />
        <Route exact path="/admin/zappadmin" component={Admin} />
        <Route exact path="/apps" component={Apps} />
        <Route exact path="/apps/pdf-app" component={DocumentEditor} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/apps/image-app" component={PhotoEditor} />
        <Route exact path="/apps/meet-app" component={MeetApp} />
        <Route exact path="/apps/tts-app" component={TTSApp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/apps/remote-desktop" component={RemoteDesktopApp} />        
        <Route exact path="/apps/remote-desktop-host" component={RDHost} />
        <Route exact path="/apps/remote-desktop-viewer" component={RDViewer} />
        <Redirect from="*" to="/404" />
      </Switch>
      <Footer />
      </Router>
  );
}

export default App;
