import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import LoadingComponent from '../LoadingComponent';
import { ImCross } from "react-icons/im"
import CopyIconComp from '../CopyIconComp';
import Button from '../Button';
import InputComp from '../InputComp';
import SmallButton from '../SmallButton';
import forge from 'node-forge';
import { fetchWrapper } from '../../utils/apiHandlers';
import HorizontalLine from '../horizontalLine';

interface MessageModalI {
  item?: any;
  state: boolean;
  handleClose(): void;
  progress?: boolean;
  showconfirmmodal?:any;
  confirmmodalhandle?:()=>void;
  showpassword: boolean;
  public_key: any;
  confirmdelete: ()=>void;
  openModal: (h?:any, m?:any, progress?:any)=>void
  closeModal: ()=> void;
}

const CustomModal: React.FC<MessageModalI> = ({
  item,
  state,
  handleClose,
  progress,
  showpassword,
  public_key,
  confirmdelete,
  openModal,
  closeModal,
  
}) => {

  const [ edit, setedit ] = useState(false)
    const [ name, setname ] = useState("")    
    const [ username, setusername ] = useState("")
    const [ password, setpassword ] = useState("")
    const [ recovery, setrecovery ] = useState<any>([])

    const [ newquestion, setnewquestion ] = useState("")
    const [ newanswer, setnewanswer ] = useState("")
    const [ toggleitem, settoggleitem ] = useState(false)
    

    const editItem = () => {
      setname(item.name)
      setusername(item.username)
      setpassword(item.password)
      let recovery = []
      for(let i=0; i<item.recovery.length; i++){
          recovery.push(item.recovery[i])
      }
      setrecovery(recovery)
      setnewanswer("")
      setnewquestion("")
      setedit(!edit)
  }

  const updaterecoveryitemq = (e: any, id: any) => {
      recovery[id].question = e.target.value
      setrecovery(recovery)
      settoggleitem(!toggleitem)
  }

  const updaterecoveryitema = (e: any, id: any) => {
      recovery[id].answer = e.target.value
      setrecovery(recovery)
      settoggleitem(!toggleitem)
  }

  const addnewRecovery = ()=>{
      if(newquestion==""||newanswer=="")return
      let newr = {
          question:newquestion,
          answer: newanswer
      }
      if(recovery && recovery.length>0){
          recovery.push(newr)
          setrecovery(recovery)
      }else{
          setrecovery([newr])
      }
      setnewquestion("")
      setnewanswer("")
      settoggleitem(!toggleitem)
  }

  const updateItem = async () => {

      if(!public_key || public_key==""){
          openModal("Warning", "Encryption keys are not set. Please go to profile and generate new keys", false)
          return
      }
      openModal("Updating", "", true)

      try{
          
          let public_Key = forge.pki.publicKeyFromPem(public_key)
          let pwd = forge.util.encode64(public_Key.encrypt(forge.util.encodeUtf8(password)))
          let encryptedRecovery: any = []
          if(!recovery){

          }else{
              for(let i=0; i<recovery.length; i++){
                  let r:any={
                      question: forge.util.encode64(public_Key.encrypt(forge.util.encodeUtf8(recovery[i].question))),
                      answer: forge.util.encode64(public_Key.encrypt(forge.util.encodeUtf8(recovery[i].answer))),
                  }
                  encryptedRecovery.push(r)
              }
          }


          let response = await fetchWrapper.post("/api/crypto/updatepwd", { email: item.email,name: name, password: pwd, recovery: encryptedRecovery, username:username, id: item._id})
          console.log(`response: ${JSON.stringify(response)}`)
          if(response.status==200){
              item.name = name
              item.password = password
              item.username = username
              item.recovery = recovery
              setedit(false)
              closeModal()
          }else{
            openModal("Error", "Failed to update, please try again later or contact support@zappletz.com", false)
          }
      }catch(error){
        openModal("Warning", "Failed to update password, please try again later or contact support@zappletz.com", false)
      }
          
  }

  const generateStrongPassword = (length = 16) => {
      const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
      const numberChars = '0123456789';
      const specialChars = '!@#$%^&*()_+[]{}|;:,.?';
    
      const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;
      let password = '';
    
      // Ensure at least one character from each category is present
      password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
      password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
      password += numberChars[Math.floor(Math.random() * numberChars.length)];
      password += specialChars[Math.floor(Math.random() * specialChars.length)];
    
      // Fill the rest of the password length with random characters
      for (let i = password.length; i < length; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
      }
    
      // Shuffle the password to ensure the order is random
      password = password.split('').sort(() => 0.5 - Math.random()).join('');
    
      setpassword(password)
    }
  

  useEffect(() => {
    setedit(false)
  }, [item])
  useEffect(() => {}, [toggleitem])


  return (
    <>
      {state && (
        <>
          <StyledBackdrop />
          <StyledModal>
          <HeaderContainer>

            <CardHeader>{item.name.substring(0, 25)}</CardHeader>
            <CloseIcon onClick={handleClose}><ImCross fontSize={"20px"}/></CloseIcon>

          </HeaderContainer>

          {edit?
              <CardDetails>
                  <ItemHeaderMain>Edit</ItemHeaderMain>
                  <div>
                      <InputComp id="name_id" placeholder='name' value={name} onChange={(e:any)=>setname(e.target.value)} width={"150px"}/>
                      <InputComp id="username_id" placeholder='username' value={username} onChange={(e:any)=>setusername(e.target.value)} width={"150px"}/>
                      <InputComp id="pwd_id" placeholder='password' value={password} onChange={(e:any)=>setpassword(e.target.value)} width={"150px"}/>
                      <SmallButton text="Suggest Password" handleClick={()=>generateStrongPassword()} /> 

                  </div>
                  {item.recovery&&item.recovery.length>0?
                      <ItemHeader>Recovery</ItemHeader>:<></>
                  }
                  {
                      recovery.map((v: any, id: any)=>(
                          <div key={`revqa_${id}`}>
                              <InputComp id={`recoveryq${id}_${item._id}`} placeholder='question' value={v.question} onChange={(e)=>updaterecoveryitemq(e, id)} width={"150px"}/>
                              <InputComp id={`recoverya_${id}_${item._id}`} placeholder='answer' value={v.answer} onChange={(e)=>updaterecoveryitema(e, id)} width={"150px"}/>
                          </div>
                      ))
                  }
                  <ItemHeader>Add Recovery QA</ItemHeader>
                  <div>
                      <InputComp id={`recoveryq_new`} placeholder='new question' value={newquestion} onChange={(e)=>setnewquestion(e.target.value)} width={"150px"}/>
                      <InputComp id={`recoverya_new`} placeholder='new answer' value={newanswer} onChange={(e)=>setnewanswer(e.target.value)} width={"150px"}/>
                      <SmallButton text="Add recovery" handleClick={()=>addnewRecovery()} /> 
                  </div>
              </CardDetails>:
              <CardDetails>
                  <ItemHeaderMain>{item.name}</ItemHeaderMain>
                  <HorizontalLine />
                  <ItemHeader>Username</ItemHeader>
                  <Itemdiv>
                    <ItemText>{item.username}</ItemText>
                    <Copydiv><CopyIconComp value={item.username} /></Copydiv>
                  </Itemdiv>
                  <ItemHeader>Password</ItemHeader>
                  <Itemdiv>
                    <ItemText>{showpassword?item.password:"********"}</ItemText>
                    <Copydiv><CopyIconComp value={item.password} /></Copydiv>
                </Itemdiv>
                  <HorizontalLine />
                  {item.recovery&&item.recovery.length>0?
                      <ItemHeaderMain>Recovery</ItemHeaderMain>:<></>
                  }
                  {
                      item.recovery&&item.recovery.map((v:any, id:any)=>(
                          <>
                              <ItemHeader>Question</ItemHeader>
                              <ItemText>{v.question}</ItemText>
                              <ItemHeader>Answer</ItemHeader>
                              <Itemdiv>
                                <ItemText>{v.answer}</ItemText> 
                                <Copydiv><CopyIconComp value={v.answer} /> </Copydiv>
                              </Itemdiv>
                              <HorizontalLine />
                          </>
                      ))
                  }
              </CardDetails>
          }
        <Editdiv>
            {edit?
            <ButtonContainer>
                <Button text='Update' handleClick={()=>updateItem()} />
                <Button text="delete" handleClick={confirmdelete} backgroundColor={"#FF5578"} />
            </ButtonContainer>
                :
                <></>
               
            }
            <ButtonContainer>
                <Span>
                    <input id={`edit_item_${item._id.substring(0,5)}`} type='checkbox' checked={edit} onChange={()=>editItem()} />
                    <Label htmlFor={`edit_item_${item._id.substring(0,5)}`}>Edit</Label>
                </Span>
            </ButtonContainer>
        </Editdiv>

              {
                progress?<LoadingComponent /> :<></>
              }

          </StyledModal>
        </>
      )}
    </>
  )
}

const Slidein = keyframes`
  0% {
    transform: translate(-50%, 100%)
  }
  100% {
    transform: translate(-50%, -50%)
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #2487D5;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  color: #ffffff;
`

const StyledBackdrop = styled.div`
  background-color: #000;
  opacity: 0.75;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 500%;
  z-index: 999;
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: #ffffff;
  border: 1px solid #2487D5;
  border-radius: 10px;
  width: min(500px, 75%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  height: 475px;

  animation: ${Slidein} 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
`;

const CloseIcon = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
  background-color: transparent;
  color: #ffffff;
  // width: auto
  transition: ease 0.5s;
  &:hover {
      box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1);
  }
`;  

const ButtonContainer = styled.div`
    display: flex;
`;

const Span = styled.span`
    padding: 7px;
`;

const Label = styled.label`
    font-size = 14px;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
`;

const ItemHeaderMain = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #000;
`;  

const ItemHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin: 3px 7px;
`
const Itemdiv = styled.div`
    display: flex;
    align-items: center;
`;

const Copydiv = styled.div`
    display: flex;
    flex: 0.1;
    align-items: center;
`;

const ItemText = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: #000;
    margin: 3px 7px;
    word-break: break-all;
    flex-wrap: wrap;
`;

const CardContainer = styled.div`
    display: flex;
    // width: 400px;
    flex-direction: column;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    padding: 0;
    margin: 20px;
    height: 500px;
    border-radius: 10px;
    &:hover {
    box-shadow: 0px 8px 8px 0px rgba(100, 100, 100, 0.75);
    }
    @media(max-width:700px){
        width: 90%;
        margin: 10px 0px;
    }
`

const CardHeader = styled.h2`
    // background-color: #2487D5;
    color: #ffffff;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: 0;
    text-align: flex-start;
    font-size: 25px;
    font-weight: 400;
    border-radius: 10px 10px 0 0;
  `

const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    color: #000;
    height: 90%;
    font-size: 16px;
    font-weight: 300;
    justify-content: flex-start;
    align-items: center;
    // width: 100%;
    padding: 20px 20px;
    white-space: pre-wrap;
    word-break: break-word;
    line-break: normal;
    line-height: 1.5;
    overflow-y: scroll;
`

const Editdiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100%;
    padding: 10px;
`;




export default CustomModal
