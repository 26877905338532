import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import gsap from 'gsap'
import ZappImage from "../assets/zappletz.png"

interface Prop{
  history?: any
}
const Home : React.FC<Prop>=(prop)=>{
	const history = useHistory()
	const cardsRef: any = useRef()
	const [ show, setshow ] = useState(false)
	const text = "zappletZ".split("");
	const [index, setIndex] = useState(0);
	const boxRef: any = useState(null);
	const letterRefs = useRef<any>([]);
	const midDiv = useRef<HTMLDivElement>(null);
	const executeScroll = () => {
		midDiv.current?.scrollIntoView({ behavior: "smooth" });
	};

	// useEffect(() => {
	// 	letterRefs.current = letterRefs.current.slice(0, text.length);
	//   }, [text.length]);
	
	//   useEffect(() => {
	// 	const interval = setInterval(() => {
	// 	  setIndex((prev) => (prev < text.length - 1 ? prev + 1 : prev));
	// 	}, 500);
	
	// 	return () => clearInterval(interval);
	//   }, []);
	
	//   useEffect(() => {
	// 	if (boxRef.current && letterRefs.current[index]) {
	// 	  gsap.to(boxRef.current, {
	// 		x: letterRefs.current[index].offsetLeft,
	// 		duration: 0.4,
	// 		ease: "power2.out",
	// 	  });
	// 	}
	//   }, [index]);
  	return(
		<MainContainer style={{}}>
			{/* <Navbar /> */}
			<ContentContainer>
				<Column>
				<Link href="/"><Link href="/"><Image src={ZappImage} alt='zappletz'/></Link></Link>
					{/* <Header>zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}
					{/* <Header>
					{text.map((letter, i) => (
						<span
						key={i}
						ref={(el) => (letterRefs.current[i] = el)}
						style={{ position: "relative", padding: "0 5px" }}
						>
						{letter}
						</span>
					))}
					</Header> */}
      				{/* <BoxText ref={boxRef}>{text[index]}</BoxText> */}
					<Caption>home of your every day apps</Caption>
				</Column>
				<ColumnX>
				<Card ref={cardsRef}>
						<SubHeader>Popular APPS</SubHeader>
						<LI href='/password-manager'>Password Manager</LI>
						<LI href='/apps/pdf-app'>PDF Manager</LI>
						<LI href='apps/photo-app'>Photo Editor</LI>
						<LI href='/apps/tts-app'>Text 2 Speech</LI>
						<LI href='/apps/meet-app'>P2P Video Meet</LI>
						<LI href='/apps/remote-desktop'>Remote Desktop</LI>
					<Row style={{marginTop: "0.7rem"}}>
						{/* <Button text="Register" handleClick={()=>prop.history.push("/register")} /> */}
						<Button text="ALL Apps" handleClick={()=>prop.history.push("/apps")} />
					</Row>
				</Card>
				</ColumnX>
			</ContentContainer>
			{/* <Footer /> */}
		</MainContainer>
  );
}
	// display: grid;
	// grid-column: 1 / span 2;

const MainContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`

const ContentContainer = styled.div`
	grid-column: 1fr;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	@media(max-width: 900px){
		grid-template-columns: repeat(1, 1fr);
	}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	width: min(75%, 350px);
	height: auto;
	margin: 1.25rem;
	padding: 1.75rem;
	align-items: center;
	border-radius: 1.2em;
	// background: linear-gradient(180deg, rgba(75, 159, 225, 0.25) 75%, rgba(255,255,255,0.5) 100%);
	background: white;
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
	box-shadow: 0 0 10px rgba(75, 84, 225, 0.25);
	@media(max-width: 900px){
		padding: 10px;
		margin: 0;
	}
`;
const Link = styled.a`
	display: flex;
	justify-content: center;
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const arrowAnimation = keyframes`
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.05);}
    100% { -webkit-transform: scale(1);}
`;

const Column = styled.div`
	grid-column: 1fr;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 20px;
	@media(max-width: 900px){
		padding: 0;
		// width: 100%;
		min-height: 10%
		height: 50%;
	}
`;

const ColumnX = styled.div`
	grid-column: 1fr;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 20px;
	@media(max-width: 770px){
		padding: 0;
		width: 100%;
	}
`;

const Row = styled.div`
	user-select: none;
	flex-wrap: wrap;
	justify-content: center;
`
const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
	border-radius: 7px;
	margin-left: 0.1em
	// color: #4B9FE1;
`;

// const BoxText = styled.div`
//   position: absolute;
//   width: 60px;
//   height: 100px;
//   background: #e14b9f;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 6rem;
//   font-weight: 700;
//   top: 0;
// `;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 770px){
		font-size: 3.5em;
	}
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const LI = styled.a`
	font-size: 1.15rem;
	padding: 0.5em;
	cursor: pointer;
	color: #FFD700;
	color: #E14B9F;
	text-align: center;
	text-decoration: none;
	font-weight: 700;
	&:hover{
	    font-weight: 700;
	}
`;

const AnimationContainer = styled.div`
	line-height: 1.75em;
	font-size: 1rem;
	margin: 5px;
`;


export default Home;