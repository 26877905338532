import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import TextboxComp from '../../components/TextboxComp';
import Button from '../../components/Button';
import { fetchWrapper } from '../../utils/apiHandlers';
import MessageModal from '../../components/MessageModal';
import download from 'downloadjs'

interface Prop{
}
const Text2Speech : React.FC<Prop>=({})=>{
    const [ tts_text, settts_text ] = useState("")
    const [ modalstate, setmodalstate ] = useState(false)
    const [ modalheader, setmodalheader ] = useState("")
    const [ modalmessage, setmodalmessage ] = useState("")
    const [ modalprogress, setmodalprogress ] = useState(false)
    const [ filename, setfilename ] = useState("")
    
    const openModal = (h: any, m: any, p?: any)=>{
        setmodalheader(h)
        setmodalmessage(m)
        setmodalprogress(p?true: false)
        setmodalstate(true)
    }

    const makeaudio = async () => {
        if(tts_text.length<1){
            openModal("Warning", "Enter Text to convert to speech", false)
            return
        }
        openModal("Message", "Converting text to speech", true)
        let response = await fetchWrapper.post("/api/pytts", {text: tts_text})
        console.log(`response : ${JSON.stringify(response)}`)
        setfilename(response.filename)
        settts_text("")
        openModal("Message", response.msg, false)

    }

    const downloadfile = async () => {
        if(filename==""){
            openModal("Warning", "no file found", false)
            return
        }
        var res = await fetchWrapper.getFile(
            `/api/pytts/downloadaudiofile?filename=${filename}`,
          ).then(async (res)=>{
    
                var blob: any = await res.blob() 
                var savefilenamearr = filename.split("/")
                var savefilename = savefilenamearr.join('_') //[savefilenamearr.length-1];
                // download(blob)
                download(blob, `${savefilename}`)
                setfilename("")
          }).catch((error)=>{
            openModal("Error", "File not found.", false)
          })
    }
    
    return(
        <MainContainer>
            <MessageModal state={modalstate} header={modalheader} message={modalmessage} progress={modalprogress} handleClose={()=>setmodalstate(false)} />
            <Column>
                <Header>Text 2 Speech</Header>
                <TextboxComp id="tts_tb" placeholder='Enter text here' value={tts_text} onChange={(e: any)=>settts_text(e.target.value)} rows={50} cols={50} />
                <Row>
                    <Button text="convert" handleClick={makeaudio}/>
                    <Button text="download" handleClick={downloadfile}/>
                </Row>
            </Column>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
user-select: none;
    display: flex;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;
export default Text2Speech