import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import ContactComponent from '../components/contactComponent/contactComponent';
import ZappImage from "../assets/zappletz.png"

interface Prop{
}
const Support : React.FC<Prop>=({})=>{
  return(
    <MainContainer>
		<ContentContainer>
		<Column>
		<Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
			{/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}
        	<Caption>limited support provided at support@zappletz.com, please check faq for more infomation.</Caption>
			</Column>
        <Column style={{overflow: "hidden"}}>
          <ContactComponent />
        </Column>
		</ContentContainer>
    </MainContainer>
  );
}


const MainContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`

const ContentContainer = styled.div`
	grid-column: 1fr;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	@media(max-width: 900px){
		grid-template-columns: repeat(1, 1fr);
	}
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default Support