import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import { validateEmail } from "../utils/helperfunctions";
import Button from "../components/Button";
import { fetchWrapper } from "../utils/apiHandlers";
import { userContext } from "../usercontext";
import { useHistory } from "react-router-dom";
import InputComp from "../components/InputComp";
import ZZZ from "../assets/zzz1.svg"
import ZappImage from "../assets/zappletz.png"
interface Props{

}

const Register: React.FC<Props> = (props) => {
  const history = useHistory()
  const [user, setUser] = useState<any>(null)
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [modalheader, setmodalheader] = useState('');
  const [modalmessage, setmodalmessage] = useState('');
  const [modalstate, setmodalstate] = useState(false);
  const [verificationcode, setverificationcode] = useState('');
  const [coderequested, setcoderequested] = useState(false);
  const [disableemail, setdisableemail] = useState(false);
  const [ registrationsuccessful, setregistrationsuccessful ] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message);
    setmodalstate(true);
  }

  const register = async () => {

    let response = await userContext.register(email, verificationcode, password).then((r: any)=>r)
    console.log(`response : ${JSON.stringify(response)}`)
    
    if(!response){
      openModal("Warning", "Invalid code.")
      return
    }
    setregistrationsuccessful(true)
    // history.push("/dashboard")
  }

  const requestcode = async () => {
    if(!validateEmail(email)){
      openModal("Error", "Invalid email address.")
      return;
    }

    const body = {
        email: email,
    }
    await fetchWrapper.post("/api/authenticate/getemailverificationcode", body).then((res:any) => {
      if(res.status==200){
          setcoderequested(true);
          setdisableemail(true);
          openModal("Message", res.msg);
        }else{
          openModal("Error", res.msg);
      }

    }).catch((error:any)=> {
        console.log(error)
        openModal("Error", "Failed, please try again.");
      })
  }

  useEffect(()=>{
    if(!registrationsuccessful)return
    let user: any = localStorage.getItem("user")
    
    user = JSON.parse(user)
    if(user && user.status==200){
      history.push("/password-manager")
    }
  }, [registrationsuccessful])

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    // console.log(`user ${JSON.stringify(user)}`)
    if(!user)return;
    if(user.status==200){
      history.push("/password-manager")
    }
  },[user])

  return(
    <MainContainer>
		<MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)}/>
		<Row>
		<Column>
    <Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
		{/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}

		<Caption>registration is required for password manager</Caption>

		<Card>
			<SubHeader>Register</SubHeader>
			<InputComp id="inputcomp" placeholder="email" value={email} onChange={(e) => setemail(e.target.value)} disabled={disableemail} />
			{!coderequested?
				<Button handleClick={requestcode} text="Request Code"/>
				:<>
				<Input placeholder="verification code" value={verificationcode} onChange={(e) => setverificationcode(e.target.value)} />
				<Input type="password" placeholder="password" value={password} onChange={(e) => setpassword(e.target.value)} autoComplete={"new-password"} />
				<Button handleClick={register} text="Register"/>
				</>
			}
		</Card>
		</Column>
		<ColumnHIW >
			{/* <StyledImage style={{position:"absolute"}} src={ZZZ} alt="zzz image"/> */}

            <SubHeader>How it works</SubHeader>
            <ul>
            <LI>Register an account with your email and password. This password is only for logging into zappletz.com.</LI>
            <LI>To save information, go to the profile page and generate encryption keys. Choose a strong "Passphrase."</LI>
            <LI>The "Passphrase" is the only information you need to remember. It is not stored anywhere.</LI>
            <LI>If you forget or lose your passphrase, all your stored information will be lost.</LI>
            <LI>You can reset your passphrase by generating new keys, but you will lose your previously saved information.</LI>
            <LI>To save passwords click on the Save menu enter your infomation and save.</LI>
            <LI>To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter</LI>
            </ul>
		</ColumnHIW>
		</Row>

  </MainContainer>
  );
};

const LI = styled.li`
  padding: 7px;
  font-size: 14px;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 75vh;
	justify-content: center;
	align-items: center;
	@media (max-width: 900px) {
		padding: 0;
		margin: 0;
	}
`

const Row = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	padding: 0;
	margin: 0;
	@media (max-width: 900px) {
		flex-direction: column;
	}
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media (max-width: 900px) {
	margin: 0.6em;
	padding: 0px;
  }
`
const StyledImage = styled.img`
    position: absolute;
    width: 40%;
    height: 40%;
    object-fit: cover;
    opacity: 0.05;
    @media (max-width: 900px) {
        width: 100%;
        height: 100%;
    }
  `

const ColumnHIW = styled.div`
	display: flex;
	flex:1;
	flex-direction: column;
	margin: 0.6em;
	margin: 0.6em, 2.5em;
	padding: 20px;
	// background-color: #8a00e2;
	background:rgba(107, 160, 200, 0.75);
	color: #FFFFFF;
	border-radius: 15px;
	box-shadow: 0 0 4px rgba(0,0,0,0.6)
	@media (max-width: 900px) {
		margin: 2.5em;
		padding: 0px;
  	}
`
const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	background: #E14B9F;
`;

const Input = styled.input`
    padding: 10px;
    background-color: #eee;
    border: 1px solid black;
    margin: 10px;
    width: 250px;
    border-radius: 3px;
`
const Card = styled.div`
	display: flex;
	flex-direction: column;
	width: min(95%, 450px);
	margin: 3.5rem;
	padding: 1.75rem;
	align-items: center;
	border-radius: 1.2em;
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
	// background: linear-gradient(180deg, rgba(75, 159, 225, 0.25) 75%, rgba(255,255,255,0.5) 100%);
	box-shadow: 0 2px 4px 0 rgba(34,110,201, 0.25);
  @media(max-width: 900px){
    margin: 0.6em;
  }
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default Register;
