import React, { useState, useCallback, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Button from '../SmallButton'
import { ImCross } from "react-icons/im"
// import {
//   StyledBackdrop,
//   StyledModal,
//   HeaderContainer,
//   Header,
//   Body,
//   CloseIcon,
//   ButtonContainer,
// } from "./styles";

interface ConfirmModalI {
  header: string
  message: string
  state: boolean
  handleClose(): void
  func(): void
}

const ConfirmModal: React.FC<ConfirmModalI> = ({
  header,
  message,
  state,
  handleClose,
  func,
}) => {
  useEffect(() => {}, [state])

  return (
    <>
      {state && (
        <>
          <StyledBackdrop />
          <StyledModal>
            <HeaderContainer>
              <Header>{header}</Header>
              <CloseIcon onClick={handleClose}><ImCross fontSize={"20px"} color={"#2487D5"}/></CloseIcon>
            </HeaderContainer>
            <Body>
              <>
                <h3>{message}</h3>
                <ButtonContainer>
                  <Button handleClick={func} text='Yes' width={75}  backgroundColor={"#FF5578"}/>
                  <Button handleClick={handleClose} text='No' width={75} backgroundColor={"#2487D5"}/>
                </ButtonContainer>
              </>
            </Body>
          </StyledModal>
        </>
      )}
    </>
  )
}

const Slidein = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

const StyledBackdrop = styled.div`
  background-color: #000;
  opacity: 0.95;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1000%;
  z-index: 999;
`

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: #ffffff;
  border-radius: 10px;
  width: min(500px, 75%);
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  animation: ${Slidein} 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
`

const ButtonContainer = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #eee;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  color: #ffffff;
`

const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 600;
  color: #0F2557;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 2;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin: 10px;
  padding: 10px;
  text-align: center;
`
const CloseIcon = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
  background-color: transparent;
  color: #c1c1c1;
  width: auto;
  transition: ease 0.5s;
  &:hover {
      box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1);
  }
`; 

export default ConfirmModal
