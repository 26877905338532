import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components';
import InputComp from '../InputComp';
import Button from '../Button';
import SmallButton from '../SmallButton';
import { fetchWrapper } from '../../utils/apiHandlers';
import { userContext } from '../../usercontext';
import MessageModal from '../MessageModal';
import HorizontalLine from '../horizontalLine';
import { generateStrongPassword } from '../../utils/helperfunctions';
import forge from 'node-forge';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useHistory } from 'react-router-dom';

interface Props{
}

interface Recovery{
    question?: any,
    answer?: any,
}

const CryptoComp : React.FC<Props>=(props)=>{
    const history = useHistory()

    const [ name, setname ] = useState("")
    const [ username, setusername ] = useState("")    
    const [ pwd, setpwd ] = useState("")
    const [user, setUser] = useState<any>(null) 
    
    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)
    const [recovery, setrecovery] = useState<any>(null)
    const [ question, setquestion ] = useState("")
    const [ answer, setanswer ] = useState("")
    const [ toggle, settoggle ] = useState(false)
    const [ showpwd, setshowpwd ] = useState(false)
  
    const openModal = (header: string, message: string, progress?: boolean) => {
      setmodalheader(header)
      setmodalmessage(message)
      setmodalstate(true)
      setmodalprogress(progress?progress: false)
    }
    
    // const generateStrongPassword = (length = 16) => {
    //     const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    //     const numberChars = '0123456789';
    //     const specialChars = '!@#$%^&*()_+[]{}|;:,.?';
      
    //     const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;
    //     let password = '';
      
    //     // Ensure at least one character from each category is present
    //     password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
    //     password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
    //     password += numberChars[Math.floor(Math.random() * numberChars.length)];
    //     password += specialChars[Math.floor(Math.random() * specialChars.length)];
      
    //     // Fill the rest of the password length with random characters
    //     for (let i = password.length; i < length; i++) {
    //       password += allChars[Math.floor(Math.random() * allChars.length)];
    //     }
      
    //     // Shuffle the password to ensure the order is random
    //     password = password.split('').sort(() => 0.5 - Math.random()).join('');
      
    //     setpwd(password)
    //   }

    
    const savepwd = async () => {
        if(!user.public_key || user.public_key==""){
            openModal("Warning", "Encryption keys are not set. Please go to profile and generate new keys", false)
            return
        }

        if(name==""){
            openModal("Warning", "Label is required", false)
            return
        }
        if(pwd==""){
            openModal("Warning", "Password is required", false)
            return
        }
        try{
            
            let public_key = forge.pki.publicKeyFromPem(user.public_key)
            let password = forge.util.encode64(public_key.encrypt(forge.util.encodeUtf8(pwd)))
            let encryptedRecovery: any = []
            if(!recovery){

            }else{
                for(let i=0; i<recovery.length; i++){
                    let r:any={
                        question: forge.util.encode64(public_key.encrypt(forge.util.encodeUtf8(recovery[i].question))),
                        answer: forge.util.encode64(public_key.encrypt(forge.util.encodeUtf8(recovery[i].answer))),
                    }
                    encryptedRecovery.push(r)
                }
            }


            let response = await fetchWrapper.post("/api/crypto/savepwd", {name: name, password: password, email:user.email, recovery: encryptedRecovery, username:username})
            console.log(`response: ${JSON.stringify(response)}`)
            if(response.status==200){
                openModal("Message", "Password added", false)
                setname("")
                setpwd("")
                setusername("")
                setrecovery([])
            }
        }catch(error){
            console.log(`Error: ${error}`)
            
            openModal("Warning", "Failed to add password, please try again later or contact support@zappletz.com", false)

        }
        
    }

    const addrecovery = () => {
        if(question==""||answer==""){
            openModal("Warning", "Enter recovery question and answer", false)
            return
        }
        let r:Recovery={
            question:question,
            answer:answer
        }
        if(!recovery)
        {
            let recovery:any=[]
            recovery.push(r)
            setrecovery(recovery)
        }else{
            recovery.push(r)
            console.log(`recovery : ${JSON.stringify(recovery)}`)
            setrecovery(recovery)
        }
        settoggle(!toggle)
        setquestion("")
        setanswer("")
    }
    const getpwd = () => {
        let pwd = generateStrongPassword()
        setpwd(pwd)
    }
    

    useEffect(() => {
        const subscription = userContext.user.subscribe((x:any) => setUser(x))
        return () => subscription.unsubscribe()
      }, [])
    
      useEffect(()=>{
        if(!user)return;
        if(user.status!==200){
          history.push("/login")
        }
      },[user])

      useEffect(()=>{

      },[recovery])

    return(
        <MainContainer>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />

            <Column>
                <Header>Save Passwords</Header>
                <div >
                    <InputComp id="name_id" placeholder='Label (details)' onChange={(e: any)=>setname(e.target.value)} value={name} />
                    <InputComp id="user_id" placeholder='username/email' onChange={(e: any)=>setusername(e.target.value)} value={username} />
                    <NewPwdContainer>
                        <InputComp id="pwd_id" placeholder="password" onChange={(e: any)=>setpwd(e.target.value)} value={pwd} type={showpwd?"text":'password'}/>
                        {showpwd?<AiOutlineEye onClick={()=>setshowpwd(!showpwd)}/> : <AiOutlineEyeInvisible onClick={()=>setshowpwd(!showpwd)}/>}
                    </NewPwdContainer>
                </div>
                    <SmallButton text="suggest password" handleClick={()=>getpwd()} />
                {
                    recovery&&recovery.map((v: any, id: any) => (
                        <ButtonContainer>
                            <InputComp key={`recovery_q_${id}`} id={`recovery_q_${id}`} placeholder='recovery question' onChange={(e: any)=>{}} value={v.question} />
                            <InputComp key={`recovery_a_${id}`} id={`recovery_a_${id}`} placeholder='recovery answer' onChange={(e: any)=>{}} value={v.answer} />

                        </ButtonContainer>
                    ))
                }
                <SubHeader>Enter your password recovery questions</SubHeader>

                <InputComp key={`recovery_q_000`} id={`recovery_q_000`} placeholder='recovery question' onChange={(e: any)=>setquestion(e.target.value)} value={question} />
                <InputComp key={`recovery_a_000`} id={`recovery_a_000}`} placeholder='recovery answer' onChange={(e: any)=>setanswer(e.target.value)} value={answer} />
                <SmallButton text='add recovery' handleClick={addrecovery} />

                <HorizontalLine/>
                <ButtonContainer>
                    <Button text='save / update' handleClick={savepwd} />
                </ButtonContainer>
            </Column>

        </MainContainer>
    );
}

const NewPwdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
    display: flex;
`;
const Label = styled.label`
    font-size: 12px;
    color: #E14B9F;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Row = styled.div`
user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   @media (max-width: 770px) {
//     padding: 20px 50px;
//   }
// `

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  @media (max-width: 770px) {
    padding: 20px 10px;
  }
`
const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 25px;
    color: #000000;
`;

const SubHeader = styled.div`
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    justify-content: flex-end;
    color: #000000;
`;

const RowHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 3px;
  border-bottom: 1px solid black;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`

const ItemHeader = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
`;

const Item = styled.div`
    display: flex;
    flex: 1;  
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    overflow: hidden;
`;

export default CryptoComp