import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router-dom';
import PwdManagerImage from "./images/password-manager.jpg"
import StrongPwdImage from "./images/how-strong-is-my-password.jpg"
import ZappImage from "../../assets/zappletz.png"

const blogs = [
    {
        title: "How Strong is your Password?",
        image: StrongPwdImage,
        url: "/blogs/how-strong-is-your-password",
    },
    {
		title: "Why Password Manager?",
		image: PwdManagerImage,
		url: "/blogs/why-password-manager",
    },

  ];
  
interface Prop{
}
const Blogs : React.FC<Prop>=({})=>{
    const history = useHistory()
  return(
    <MainContainer>
		<ContentContainer>
		<Column>
		<Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
		{/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}
		<SubHeader>Blogs</SubHeader>
		</Column>
				<Column>

					<BlogTiles>
						{blogs?.map((value, indx) => (
						<BlogTile key={`blog${indx}`}>
							<BlogTileDiv
							key={indx}
							onClick={() => {
								history.push(value.url);
							}}
							style={{backgroundImage:`url(${value.image})`,backgroundSize:'cover'}}
							>
								{/* <Image src={value.image} alt={`${value.title}`} height={250} width={250} /> */}
							</BlogTileDiv>
							{value.title}
						</BlogTile>
						))}
			</BlogTiles>  
				</Column>
		</ContentContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`

const ContentContainer = styled.div`
	grid-column: 1fr;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	@media(max-width: 900px){
		grid-template-columns: repeat(1, 1fr);
	}
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;

const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const BlogTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-around;
`;

const BlogTile = styled.div`
  margin: 25px 25px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const BlogTileDiv = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  box-shadow: -4px 10px 35px -1px rgba(0, 0, 0, 0.75);
  text-align: center;
  color: #000;
  flex-wrap: wrap;
  cursor: pointer;
  transition: transform 0.25s;
  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default Blogs