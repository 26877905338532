import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

interface CompProps {
    id: string;
    labels:any [];
    values?: any[];
    onChange: (e:any)=>void;
    placeholder?:string;
    width?: Number;
    value?:any;
    optionid: any;
}

const SelectComp: React.FC<CompProps> = ({
    id,
    labels,
    values,
    onChange,
    placeholder,
    width,
    value,
    optionid,
}) =>{

    const [focused, setfocused] = useState(false)

    useEffect(() => {
    }, [])

    return (
        <SelectDiv style={{width:width?`${width}px`:"250px"}}>
            {(focused||optionid!=0)&&<Label htmlFor={id} style={{userSelect:'none'}}>{placeholder}</Label>}
            <Select id={id} onChange={onChange} value={optionid}>
                {
                    labels.map((v, idx) => (
                        <option key={`${id}opt${idx}`} value={values?values[idx]:idx}
                        >{labels[idx]}</option>
                    ))
                }
            </Select>
        </SelectDiv>
    )
}

const SelectDiv = styled.div`
    display: flex;
    margin: 10px;
    flex-direction: column;
    background-color: #eee;
    border-bottom: 1px solid black;
    padding: 0;
    height: 50px;
    justify-content: center;
    opacity: 0.8;
    // min-width: 250px;
    &:hover{
        opacity: 1;
    }
`
const Select = styled.select`
    padding: 0 10px;
    margin: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    height: 35px;
    width: 100%;
`
const Label = styled.label`
    padding: 5px;
    font-size: 10px;
    font-weight: 700;
    color: #E14B9F;
    height: 25px;
    margin: 0;
`

export default SelectComp
