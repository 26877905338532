import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import Button from "../components/Button";
import { userContext } from '../usercontext';
import Input from "../components/InputComp";
import { fetchWrapper } from "../utils/apiHandlers";
import { validateEmail } from "../utils/helperfunctions";
import { useHistory } from "react-router-dom";
import SmallButton from "../components/SmallButton";
import ZappImage from "../assets/zappletz.png"

interface Props{

}

const ForgotPassword: React.FC<Props> = (props) => {
  const history = useHistory()
  const [user, setUser] = useState<any>(null)
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)
  const [pwdsent, setpwdsent] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  
  const changeemail = (e: any) => {
    setemail(e.target.value.toLowerCase())
    setpwdsent(false)
  }

  const sendtemppassword = async () => {
    if(pwdsent){
      openModal("Message", "A temporary password has been sent to your email.")
      return;
    }

    if(email==""){
      openModal("Warning", "Email is required.")
      return;
    }

    if(!validateEmail(email)){
      openModal("Warning", "Email is not valid.")
      return;
    }
    try{
      await fetchWrapper.post("/api/authenticate/gettemppwd", {email: email}).then((res)=>{
        openModal("Message", "A temporary password has been sent to your email.")
      }).catch((error)=>{
          openModal("Message", "If an account was found, a temporary password was sent to your email.")
      })
    }catch(error){
      openModal("Message", "If an account exists corresponsing to this email, a temporary password will be sent to your email.")
      console.log(`Here `);
    }
  }

    useEffect(() => {
        const subscription = userContext.user.subscribe((x:any) => setUser(x))
        return () => subscription.unsubscribe()
    }, [])

  useEffect(()=>{
    // console.log(`user ${JSON.stringify(user)}`)
        if(!user)return;
        if(user.status==200){
        history.push("/password-manager")
        }
    },[user])

  return (
    <>
		<MainContainer>
			<MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
        <ContentContainer>
      <Column>
      <Link href="/"><Image src={ZappImage} alt='zappletz'/></Link>
				{/* <Header> zapplet<BoxText>&nbsp;Z&nbsp;</BoxText></Header> */}
        <Caption>forgot your password? submit your email address to receive a temporary password. </Caption>
			</Column>
			<Column>
				<Card>
				<SubHeader>Forgot Your Password?</SubHeader>
				<Input id="loginemail" placeholder="email" value={email} onChange={(e: any) => changeemail(e)} />
				<Button handleClick={()=>sendtemppassword()} text="Submit" />
				<Info>Submit your email to receive a temporary password.</Info>
				
				</Card>
			</Column>
      </ContentContainer>
		</MainContainer>
    </>
  );
};

const MainContainer = styled.div`
	display: flex;
	min-height: 75vh;
`
const ContentContainer = styled.div`
	grid-column: 1fr;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	@media(max-width: 900px){
		grid-template-columns: repeat(1, 1fr);
	}
`;

const Column = styled.div`
	display: flex;
  	flex-direction: column;
    padding: 20px;
    justify-content: center;
	align-items: center;
    width: 100%;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 6rem;
	font-weight: 700;
	text-align: center;
    color: #ffffff;
	// color: #E14B9F;
	color: #000000;

	@media(max-width: 900px){
		font-size: 3.5em;
	}
`;


const SubHeader = styled.div`
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.75rem;
    color: #000000;
`;

const BoxText = styled.div`
	padding: 0;
	margin: 0;
	// border-bottom: 20px solid #E14B9F;
	background: #E14B9F;
`;

const Caption = styled.p`
	color: #000000; 
	lineHeight: 1.5;
	text-align: center;
	padding: 0.4em 2.5em;
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	width: min(95%, 450px);
	margin: 3.5rem;
	padding: 1.75rem;
	align-items: center;
	border-radius: 1.2em;
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
	// background: linear-gradient(180deg, rgba(75, 159, 225, 0.25) 75%, rgba(255,255,255,0.5) 100%);
	box-shadow: 0 2px 4px 0 rgba(34,110,201, 0.25);
	@media(max-width: 770px){
		margin: 0.6em;
	}
`;

const Info = styled.div`
	font-weight: 700;
	font-size: 0.9rem;
	padding: 1rem;
	color: #E14B9F;
`;

const Image = styled.img`
	width: min(450px, 75%);
`;

const Link = styled.a`
	display: flex;
	justify-content: center;
`;

export default ForgotPassword;
