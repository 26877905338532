import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SmallButton from '../SmallButton'
import SelectComp from '../SelectComp'
import { fetchWrapper } from '../../utils/apiHandlers'
import HorizontalLine from '../horizontalLine'
import LoadingComponent from '../LoadingComponent'
import { clientLogger } from '../../utils/clientLogger'
import InputComp from '../InputComp'
import TextboxComp from '../TextboxComp'

interface PropsI{
    openModal:(header: any, message: any, progress: any) => void;
}

const ShowFRUsers: React.FC<PropsI> = ({openModal}) => {


    const [ phonenumber, setphonenumber ] = useState("")
    const [ address, setaddress ] = useState("")
    const [ firstname, setfirstname ] = useState("")
    const [ lastname, setlastname ] = useState("")
    const [ phoneValid, setphoneValid ] = useState(false)

    const [ frusers, setfrusers ] = useState<any>([])
    const [ loading, setloading ] = useState(false)
    const [ confirmmodalheader, setconfirmmodalheader ] = useState("Confirm")
    const [ confirmmodelmessage, setconfirmmodelmessage ] = useState("Confirm Yes to add free credits.")
    const [ confirmmodalState, setconfirmmodalState ] = useState(false)
    const [ email, setemail ] = useState("")
    const [ confirmmodalfuncid, setconfirmmodalfuncid ] = useState(-1)
    
    const [ filtercondition, setfiltercondition ] = useState("")
    
    const [ allusers, setallusers ] = useState<any>([])
    const [ allemails, setallemails ] = useState<any>([])

    const [ filteredusers, setfilteredusers ] = useState<any>([])
    const [ filteredemails, setfilteredemails ] = useState<any>([])
    
    const [ activesubscription, setactivesubscription ] = useState(0)
    const [ activemonthly, setactivemonthly ] = useState(0)
    const [ activeyearly, setactiveyearly ] = useState(0)
    const [ selecteduseroptionid, setselecteduseroptionid ] = useState(0)
    const [ subject, setsubject ] = useState("")
    const [ emailmessage, setemailmessage ] = useState("")
    const [ verified, setverified ] = useState(false)
    const [ loadingmessage, setloadingmessage ] = useState("")

    const createstripecustomerid = async () => {
        if(filteredusers.length<1 || selecteduseroptionid<1){
            openModal("Warning", "Select user before proceeding", false)
            return
        }
        if(filteredusers[selecteduseroptionid-1].stripecustomerid!=""){
            openModal("Warning", "Select user already has a customer id", false)
            return
        }
        setloading(true)
        try{
            let user = filteredusers[selecteduseroptionid-1]
            let response = await fetchWrapper.post("/api/stripe/create-customer", {email: user.email, name: `${user.firstname} ${user.lastname}`})
            openModal("Error", `${response.data.msg}`, false)

        }catch(error){
            openModal("Error", `Error: ${error}`, false)
        }
        setloading(false)
        
    }

    const changepassword = async () => {
        if(allusers.length<1 || selecteduseroptionid<1){
            openModal("Warning", "Invalid operation", false)
            return
        }
        setconfirmmodalfuncid(0)
        setconfirmmodelmessage(`change password for ${allusers[selecteduseroptionid-1].email}`)
        setconfirmmodalState(true)
    }
    const changepasswordfunc = async () => {
        await fetchWrapper.post("/api/admin/adminchangepwd", {email:allusers[selecteduseroptionid-1].email})
        openModal("Warning", "Password changed", false)
    }
    
    
    const loadusers = async () => {
        setfiltercondition("")
        setselecteduseroptionid(0)
        try{
            let response: any = await fetchWrapper.post("/api/admin/users/get", {})
            let allusers = []
            let allemails=['--select email--']
            for(let i=0; i<response.users.length; i++){
                allemails.push(response.users[i].email)
            }
            
            setallusers(response.users)
            setfilteredemails(allemails)
            setallemails(allemails)
            setfilteredusers(response.users)
        }catch(error){
            
        }
    }

    const sendemailInputs = async (email: any, subject: any, message: any) => {
        const body = {
            recipient: email,
            message: message,
            subject: subject
        }
        await fetchWrapper.post('/api/sendemail', body).then((res) => {
            setsubject('')
            setemailmessage('')
            openModal('Message', 'Email Sent', false)
        }).catch((err) => {
            console.log('err: ', err)
            openModal('Error', 'Failed to send email.', false)
      })

    }

    const sendemail = async () => {
        if(subject==""){
            openModal("Warning", "Enter subject", false)
            return
        }
        if(emailmessage==""){
            openModal("Warning", "Enter message", false)
            return
        }
        let msg = `Hello ${allusers[selecteduseroptionid-1].firstname}, <br><br>${emailmessage}`
        const email = allusers[selecteduseroptionid-1].email
        
        await sendemailInputs(email, subject, msg)
    }

    const changeselecteduser = (e: any) => {
        setselecteduseroptionid(e.target.value)
        if(e.target.value>0){
            setphonenumber(filteredusers[e.target.value-1].phonenumber)
            setaddress(filteredusers[e.target.value-1].address)
            setfirstname(filteredusers[e.target.value-1].firstname)
            setlastname(filteredusers[e.target.value-1].lastname)
        }        
    }

    const changefilter = (e: any) => {
        setselecteduseroptionid(0)
        setfiltercondition(e.target.value)
    }

    const filterUsers = () => {
        let users = allusers
        if(verified){
            users = users.filter((user: any) =>user.verified)
        }
        users = users.filter((user: any) =>
            user.email.toLowerCase().includes(filtercondition.toLowerCase())
            || user.firstname.toLowerCase().includes(filtercondition.toLowerCase())
            || user.lastname.toLowerCase().includes(filtercondition.toLowerCase())
            );
        
        return users
    }

    const formatInput = (newTxt: string, dashPos: number[]) => {
        newTxt = newTxt.replace(/-/g, "")
        dashPos.forEach((value, indx) => {
          if (newTxt.length >= dashPos[indx]) {
            newTxt =
              newTxt.slice(0, dashPos[indx] - 1) +
              "-" +
              newTxt.slice(dashPos[indx] - 1)
          }
        })
        return newTxt;
      };

    const changePhoneNumber = (e: any) => {
        if(e.target.value.length>12)return
        const re = /^[0-9\b]+$/;
        let newTxt = e.target.value.replace(/-/g, "")
        let phone = "";
        if (newTxt === "" || re.test(newTxt)) {
          phone = formatInput(newTxt, [4, 8])
          setphonenumber(phone)
        }
        if (phone && phone.length === 12) {
          setphoneValid(true)
        } else {
          setphoneValid(false)
        }
    };

    const updateprofile = async () => {
        setloading(true)
        await fetchWrapper.post("/api/admin/users/updateprofile", {email:filteredusers[selecteduseroptionid-1].email, firstname: firstname, lastname: lastname, address: address, phonenumber: phonenumber})
        setloading(false)
    }

    const filterverifiedusers = () => {
        let users = allusers
        if(!verified){
            users = users.filter((user: any) => user.verified);
        }
        users = users.filter((user: any) =>
            user.email.toLowerCase().includes(filtercondition.toLowerCase())
            || user.firstname.toLowerCase().includes(filtercondition.toLowerCase())
            || user.lastname.toLowerCase().includes(filtercondition.toLowerCase())
            );
        let allemails=['--select email--']
        for(let i=0; i<users.length; i++){
            allemails.push(users[i].email)
        }
        setfilteredemails(allemails)
        setfilteredusers(users)
        setverified(!verified)
        return users
    }

    useEffect(()=>{
        let users = filterUsers()
        setfilteredusers(users)
        let allemails=['--select email--']
        for(let i=0; i<users.length; i++){
            allemails.push(users[i].email)
        }
        setfilteredemails(allemails)
    }, [filtercondition])

    useEffect(()=>{

    },[filteredusers, activesubscription])

    return (
        <MainContainer>
            {loading?
            <div style={{alignSelf:'center', width:"100%"}}>
                <LoadingComponent />
            </div>
            :
            <Column>
                <Header>Users</Header>
                <HorizontalLine />
                    <Item># user: {filteredusers.length}</Item>
                <HorizontalLine />
                <ButtonContainer>
                    <SelectComp id="userselectcomp1" placeholder='select user' labels={filteredemails} optionid={selecteduseroptionid} onChange={changeselecteduser} />
                    <InputComp id="filteruserinput" placeholder='filter user' value={filtercondition} onChange={changefilter} />
                </ButtonContainer>
                <span style={{fontSize:"18px"}}>
                    <input type='checkbox' checked={verified} id="verifiedcheckboxid" onChange={filterverifiedusers}/><label htmlFor='verifiedcheckboxid'>Verified</label>
                </span>
                <HorizontalLine />
                <Userline >
                    <ItemHeader>Subscription</ItemHeader>
                    <ItemHeader>Verified</ItemHeader>
                </Userline>
                <Userdetails key={`userlinedetails`}>
                {
                    selecteduseroptionid>0?
                        <Userline key={`mainuserline12fdam`}>
                            <Item key={`userline12fdd2`}>
                                {new Date(filteredusers[selecteduseroptionid-1].subscriptionenddate).toLocaleDateString()}
                            </Item>
                            <Item key={`userline12verified`}>
                                {filteredusers[selecteduseroptionid-1].verified.toString()}
                            </Item>
                        </Userline>
                    :<></>
                }
                </Userdetails>

                {
                    frusers.length>0?
                    <HorizontalLine/>:<></>
                }
                <ButtonContainer>
                    <SmallButton text='Load' handleClick={loadusers} />
                    {/* <SmallButton text='Extend Month' handleClick={()=>extendmembership(1)} /> */}
                    {/* <SmallButton text='Extend Year' handleClick={()=>extendmembership(12)} /> */}
                    <SmallButton text='Create Stripe Customer id' handleClick={createstripecustomerid} />
                    <SmallButton text='Change Password' handleClick={changepassword} />
                </ButtonContainer>
                {
                    selecteduseroptionid>0?
                        <><ButtonContainer>
                        <InputComp id="useremailinputcomp" placeholder='email' value={filteredusers[selecteduseroptionid-1].email} onChange={()=>{}} />
                        <InputComp id="userfirstnameinputcomp" placeholder='first name' value={firstname} onChange={(e: any)=>{setfirstname(e.target.value)}} />
                        <InputComp id="userlastnameinputcomp" placeholder='last name' value={lastname} onChange={(e: any)=>{setlastname(e.target.value)}} />
                        <InputComp id="userphoneinputcomp" placeholder='phone' value={phonenumber} onChange={changePhoneNumber} />
                        <InputComp id="useraddressinputcomp" placeholder='address' value={address} onChange={(e: any)=>{setaddress(e.target.value)}} />
                        <SmallButton text="update" handleClick={updateprofile} />
                        </ButtonContainer>
                        <InputComp id="usernameinputcomp" placeholder='subject' value={subject} onChange={(e: any)=>{setsubject(e.target.value)}} />

                        <TextboxComp id="emailtbcomp" value={emailmessage} onChange={(e: any) =>{setemailmessage(e.target.value)}} cols={50} rows={50} placeholder='message'/>
                        <SmallButton text='send email' handleClick={sendemail} />
                        </>
                    :<></>
                }

            </Column>
            }


        </MainContainer>
    )
}

const MainContainer = styled.div`
  margin: 25px;
  display: flex;
  box-shadow: 0px 8px 8px 0px rgba(50, 20, 50, 0.5);
  border-radius: 3px;
  width: 90%;
  color: #ffffff;
`
const Column = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
`
const Userline = styled.div`
    display: flex;
    flex:1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

const Userdetails = styled.div`
    display: flex;
    flex:1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

const ItemHeader = styled.div`
    display: flex;
    flex: 1;
    font-weight: 700;
    padding: 5px;
    justify-content: flex-start;
    word-break: break-all;
`;

const Item = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    justify-content: flex-start;
    word-break: break-all;
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default ShowFRUsers