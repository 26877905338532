import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import Button from '../../components/Button'
import MessageModal from '../../components/MessageModal';
import { io } from "socket.io-client";
import InputComp from '../../components/InputComp';
import { useHistory } from 'react-router-dom';
import { fetchWrapper } from '../../utils/apiHandlers';
import { validateEmail } from '../../utils/helperfunctions';
import { FaVideo, FaVideoSlash } from "react-icons/fa6";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { clientLogger } from '../../utils/clientLogger';


interface Prop{
}
const MeetApp : React.FC<Prop>=({})=>{
    const history = useHistory()
    // const [ socket, setsocket ] = useState<any>(null)
    const socketRef: any = useRef(null); // 🔹 Store socket connection
    const peersRef: any = useRef({})
    const socketidRef: any = useRef("")

    const [ ishost, setishost ] = useState(false)
    

    const [ modalstate, setmodalstate ] = useState(false)
    const [ modalheader, setmodalheader ] = useState("")
    const [ modalmessage, setmodalmessage ] = useState("")
    const [ modalprogress, setmodalprogress ] = useState(false)

    const localVideoRef: any = useRef(null);
    const remoteVideoRef: any = useRef(null);
    const [peerConnection, setPeerConnection] = useState(null);

    // const [peers, setPeers] = useState<any>({});
    const [hostcallId, sethostcallId] = useState("");
    // const [joinedCallId, setJoinedCallId] = useState("");
    const videoContainerRef = useRef<HTMLDivElement | null>(null);
    const [ callstarted, setcallstarted ] = useState(false)
    const [ friendemail, setfriendemail ] = useState("")
    const [ isVideoEnabled, setisVideoEnabled ] = useState(true)
    const [ volume, setvolume ] = useState(1)
    
    
    const openModal = (h: any, m: any, p?: any)=>{
        setmodalheader(h)
        setmodalmessage(m)
        setmodalprogress(p?true: false)
        setmodalstate(true)
    }

    const adjustVolume = (e: any) => {
        const newVolume: any = parseFloat(e.target.value);
        setvolume(newVolume);
        const videos = document.querySelectorAll("video");
        videos.forEach((video) => (video.volume = newVolume));
    };

    const toggleVideo = () => {
        if (localVideoRef.current && localVideoRef.current.srcObject) {
            const videoTrack = localVideoRef.current.srcObject.getTracks().find((track: any) => track.kind === "video");
            if (videoTrack) {
                videoTrack.enabled = !videoTrack.enabled;
            }
        }
        setisVideoEnabled(!isVideoEnabled);
    };

    const invitefriend = async () => {
        if(!validateEmail(friendemail))
        {
            openModal("Warning", "Invalid email")
            return
        }
        await fetchWrapper.post("/api/invitefriend", {friendemail:friendemail, callid: hostcallId})
        openModal("Message", "friend invited", false)
    }

    const addVideoStream = (socketId: string, stream: MediaStream) => {
        let videoElement = document.getElementById(socketId) as HTMLVideoElement;
        if (!videoElement) {
            videoElement = document.createElement("video") as HTMLVideoElement;
            videoElement.id = socketId;
            videoElement.autoplay = true;
            videoElement.playsInline = true;
            videoElement.style.width = "300px";
            videoElement.style.border = "1px solid gray";
            
            if (videoContainerRef&& videoContainerRef.current&& videoContainerRef.current!==null) {
                videoContainerRef.current.appendChild(videoElement);
            }
        }
        videoElement.srcObject = stream;
        videoElement.onloadedmetadata = () => {
            console.log("Playing video...");
            videoElement.play().catch((error) => console.error("Video play error:", error));
        };
    };
    
    const createPeerConnection = async (socketId: any, isCaller: any, hostcallId: any, offer?: any) => {
        console.log(`creating peer connection for socketid: ${socketId}`)

        console.log(`ice servers: ${process.env.REACT_APP_ICE_SERVERS!} `)        
        
        const pc = new RTCPeerConnection({
            iceServers: [{ urls: process.env.REACT_APP_ICE_SERVERS!}],
        });

        console.log(`logging peer connection:`)
        console.log(pc)
    
        pc.onicecandidate = (event) => {
            console.log(`onicecandidate  event received:`)
            
            if (event.candidate) {
                socketRef.current.emit("ice-candidate", {
                    candidate: event.candidate,
                    to: socketId,
                    callId: hostcallId,
                    senderid:socketidRef.current,
                });
                console.log(`this is ${socketidRef.current} is sending ice candidate to: ${socketId}`)
            }else{
                console.log(`FAILED to send ice candidate event has no candidate`)                
            }
        };
    
        pc.ontrack = (event) => {
            console.log(`ontrack event:`)
            console.log(event)
            
            console.log(`adding video streams`)            
            addVideoStream(socketId, event.streams[0]);
        };

        pc.onconnectionstatechange = () => {
            console.log(`Peer Connection state: ${pc.connectionState}`);
            if(pc.connectionState=="failed"){
                pc.restartIce()
                console.log(`pc restarted`)
                
            }
        };

        pc.oniceconnectionstatechange = () => {
            console.log("ICE State:", pc.iceConnectionState);
        }


        if (localVideoRef.current) {
            console.log(localVideoRef.current)            
            localVideoRef.current.srcObject.getTracks().forEach((track: any) => pc.addTrack(track, localVideoRef.current.srcObject as MediaStream));
        }

        // const videoTrack = localVideoRef.current.srcObject.getTracks().find((track: any) => track.kind === "video");
    
        if (isCaller) {        
            await pc.createOffer().then(async (offer) => {
                await pc.setLocalDescription(offer);
                let x = { ...peersRef.current, [socketId]: pc }
                console.log(`peer connection array`)        
                console.log(x)
                peersRef.current = x;
                socketRef.current.emit("offer", { offer: offer,  to: socketId, senderid: socketidRef.current, callId: hostcallId });
                console.log(`host created an offer and sent to socket id: ${socketId}}`)
            }).catch((error: any)=>{
                console.log(`Error in host create offer: ${error}`)                
            })

        }
        else{
            // console.log(`received offer: ${JSON.stringify(offer)}`)
            await pc.setRemoteDescription(new RTCSessionDescription(offer));
            await pc.createAnswer().then(async (answer: any)=>{
                await pc.setLocalDescription(answer);
                let x = { ...peersRef.current, [socketId]: pc }
                console.log(`peer connection array`)        
                console.log(x)
                peersRef.current = x;
                console.log(`guest created answer sending to: ${socketId}`)
                socketRef.current.emit("answer", { answer: answer, to: socketId, senderid:socketidRef.current, callId: hostcallId });
            }).catch((error: any)=>{
                console.log(`Error in guest create answer: ${error}`)
            })
        }

        // let x = { ...peersRef.current, [socketId]: pc }
        // console.log(`peer connection array`)        
        // console.log(x)
        // peersRef.current = x;
        return pc;
    };
    
    const startCall = async () => {
        const newCallId = Math.random().toString(36).substring(2, 10);
        sethostcallId(newCallId);

        if (!socketRef.current) {
            socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_PC_PORT}`);

            setupSocketListeners(newCallId);
        }
        openModal("Message", `Call started. Share this call ID: ${newCallId}`);
        try{

            await navigator.mediaDevices.getUserMedia({ video: isVideoEnabled, audio: true }).then((stream) => {
                if (localVideoRef.current) localVideoRef.current.srcObject = stream;
            }).catch((err) => {
                // console.error("Error accessing media devices:", err);
                openModal("Error", err.message, false);  // Ensure only the message is stored
                // return
            });
        
            socketRef.current.emit("start-call", newCallId);
            setcallstarted(true)
            setishost(true)
        }catch(error: any){
            openModal("Warning", error, false)
            return
        }
    };
    
    const joinCall = async () => {
        if(callstarted)return
        if (!hostcallId) {
            openModal("Warning", "Please enter a valid Call ID", false);
            return;
        }

        if (!socketRef.current) {
            socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_PC_PORT}`);
            setupSocketListeners(hostcallId);
        }
        await navigator.mediaDevices.getUserMedia({ video: isVideoEnabled, audio: true }).then((stream) => {
            if (localVideoRef.current) localVideoRef.current.srcObject = stream;
        }).catch((err) => {
            // console.error("Error accessing media devices:", err);
            openModal("Error", err.message, false);  // Ensure only the message is stored
            // return
        });
        socketRef.current.emit("join-call", {callId: hostcallId, senderid:socketidRef.current});
        setcallstarted(true)
    };

    const setupSocketListeners = (hostcallId: any) => {

        socketRef.current.on("connect", async ( data: any )=>{
            console.log(`socketid: ${socketRef.current.id}`)  
            socketidRef.current = socketRef.current.id          
        });

        socketRef.current.on("user-joined", async ( data: any )=>{
            if (hostcallId !== data.callId) return;
            console.log(`user-joined socket id: ${data.senderid}`)            
            await createPeerConnection(data.from, true, hostcallId);
        });
    
        socketRef.current.on("offer", async (data: any) => {
            console.log(`offer received: ${data.senderid}`)            
            if (hostcallId !== data.callId) return;
            await createPeerConnection(data.from, false, hostcallId, data.offer);            
        });
    
        socketRef.current.on("answer", (data: any) => {
            console.log(`answer received from: ${data.senderid}`)            
            if (peersRef.current[data.senderid]) {
                peersRef.current[data.senderid].setRemoteDescription(new RTCSessionDescription(data.answer));
            }else{
                console.log(`answer received for ${data.senderid} but no peer connection found!!`)
                
            }
        });
    
        socketRef.current.on("ice-candidate", (data: any) => {
            console.log(`ice-candidate received from ${data.senderid}: candidate:`)
            console.log(data.candidate)
            
            if (peersRef.current[data.senderid]) {
                peersRef.current[data.senderid].addIceCandidate(new RTCIceCandidate(data.candidate));
            }else{
                console.log(`ice-candidate received from ${data.senderid} but no peer connection found!!`)
                
            }
        });

        socketRef.current.on("call-finished", (data: any) => {
            console.log(`Call has ended ${Object.keys(data)}`)
            openModal("Message", "Call has ended", false)
            peersRef.current = {}
        });
    };

    const endcall = () => {
        if(!callstarted)return
        socketRef.current.emit("end-call", {callId: hostcallId, senderid:socketidRef.current})
        sethostcallId("")
        // setJoinedCallId("")
        setcallstarted(false)
    }

    useEffect(()=>{
        let id = history.location.search.split("=")[1]
        if(id){
            console.log(`setting host id from url: ${id}`)
            sethostcallId(id)
        }
    }, [])

    useEffect(() => {
    }, [hostcallId, peersRef.current, socketRef.current, socketidRef.current]);
    

    return(
        <MainContainer>
            {/* <MessageModal state={modalstate} header={modalheader} message={modalmessage} progress={modalprogress} handleClose={()=>setmodalstate(false)} /> */}
            <Column>
                <Header>P2P Meet</Header>
                {
                    socketidRef.current
                }
                <Row ref={videoContainerRef} style={{ display: "flex", justifyContent:"center", gap: "10px", flexWrap: "wrap", margin:"10px" }}>
                    <video ref={localVideoRef} autoPlay playsInline muted style={{ width: "300px", border: "1px solid gray" }} />
                    {/* <video ref={remoteVideoRef} autoPlay playsInline muted style={{ width: "300px", border: "1px solid gray" }} /> */}
                </Row>

                <Row style={{ marginTop: "10px" }}>
                    {/* <button onClick={toggleVideo}>
                    {isVideoEnabled ? "Disable Video" : "Enable Video"}
                    </button> */}
                    {isVideoEnabled ? 
                        <FaVideo fontSize={25} onClick={toggleVideo} />:
                        <FaVideoSlash fontSize={25} onClick={toggleVideo}/>
                    }
                    &nbsp;&nbsp;&nbsp;
                    
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={adjustVolume}
                        style={{accentColor: 'gray'}}
                    />&nbsp;&nbsp;&nbsp;
                    {
                        volume==0?<FaVolumeMute fontSize={25} onClick={()=>setvolume(0.5)} />:<FaVolumeUp fontSize={25} onClick={()=>setvolume(0)} />
                    }
                </Row>


                <Row style={{color:'red'}}>
                    {modalmessage}
                </Row>
                {!callstarted?
                    <Column>
                        <Row>
                            <InputComp
                                id="call_id"
                                type="text"
                                placeholder="Enter Call ID to join"
                                value={hostcallId}
                                onChange={(e) => sethostcallId(e.target.value)}
                            />
                        </Row>
                        <Row>
                            <Button text="Start Call" handleClick={startCall} />
                            <Button text="Join Call" handleClick={joinCall} />
                        </Row>
                    </Column>
                    :
                    <Column>
                        {ishost?
                        <Row>
                            <InputComp id="call id" placeholder="share this call id" value={hostcallId} onChange={()=>{}} />
                            <InputComp id="email" placeholder="participant email" value={friendemail} onChange={(e)=>setfriendemail(e.target.value)} />
                        </Row>
                        :<></>
                        }
                        <Row>
                            {ishost?
                                <Button text="Invite" handleClick={invitefriend} />
                            :<></>}
                            <Button text="End Call" handleClick={endcall} backgroundColor='#FF0000' />                        
                        </Row>
                    </Column>
                }

            </Column>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
user-select: none;
    display: flex;
    flex-wrap: wrap;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
    user-select: none;
`;

export default MeetApp