import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import MessageModal from '../../components/MessageModal'
import EditSignPDFDoc from '../../components/pdfManagerComp/editsignpdfdoc'

interface Prop{
}
const DocumentEditor : React.FC<Prop>=({})=>{

    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)

    const openModal = (header: string, message: string, progress?: boolean) => {
        setmodalheader(header)
        setmodalmessage(message)
        setmodalstate(true)
        setmodalprogress(progress?progress: false)
        
    }
    return(
        <MainContainer>
            <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
            <Column>
                <Header>PDF APP</Header>
                <SubHeader>Sign, Edit, Merge, Split Documents</SubHeader>
                <EditSignPDFDoc openModal={openModal} />
            </Column>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;import MessageModal from '../../components/MessageModal'
`;
const Row = styled.div`
user-select: none;
    display: flex;
`;
const Header = styled.div`
    font-size: 4.5rem;
    font-weight: 700;
`;

const SubHeader = styled.div`
    font-size: 2.0rem;
    font-weight: 700;
`;
export default DocumentEditor