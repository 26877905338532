import React from 'react'
import styled from 'styled-components'
import { AnimateText } from '../animations/AnimateText'

interface buttonI {
	text: string
	link?: string
	backgroundColor?: string
	color?: string
	delay?: any;
	animate?: any;
}

const MenuLink: React.FC<buttonI> = ({ text, link, backgroundColor, color, delay, animate }) => {
  return (
    <StyledButton>
      {link != '' ? <LinkDiv href={link}>{
		
			animate?
		
        <AnimateText text={text} xfrom={1500} delay={delay?delay:0} duration={0.5}/>
		:text
      
      }</LinkDiv> : <Text>{
		animate?
        <AnimateText text={text} xfrom={1500} delay={0} duration={0.5}/>
		:text
      
      }</Text>}
    </StyledButton>
  )
}

const StyledButton = styled.div`
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  // border: 2px solid transparent;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: wrap;
  word-break: break;
  @media (max-width: 900px) {
    margin: 5px;
  }
  &:hover {
    text-decoration: none;
    color: #E14B9F;
    font-weight: 700;
    // color: #FFFFFF;
  }
`

const LinkDiv = styled.a`
	text-decoration: none;
	padding: 7px;
	border-radius: 7px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	transition: ease 0.5s;
		color: #000000;
    font-weight: 700;
	&:hover {
		text-decoration: none;
		color: #E14B9F;
		font-weight: 700;
		// color: #FFFFFF;
	}
`

const Text = styled.div`
  display: flex;
  padding: 5px;
  font-size: 14px;
  transition: ease 0.5s;
  white-space: nowrap;
  &:hover {
    // text-decoration: none;
    // background-color: #63BCE5;
    // color: #FFFFFF;
  }
`

export default MenuLink
