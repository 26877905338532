import React, {useState, useEffect, useRef} from 'react'
import styled, { keyframes } from 'styled-components'
import { io } from "socket.io-client";
import Button from '../../components/Button';
import InputComp from '../../components/InputComp';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../../utils/helperfunctions';
import { fetchWrapper } from '../../utils/apiHandlers';
import download from 'downloadjs'

const iceServers = [
    { urls: "stun:stun.l.google.com:19302" }, // Google's STUN server
    { urls: "stun:stun1.l.google.com:19302" }, // Backup STUN server
]

declare module 'react' {
    interface MediaTrackConstraintSet {
        video?: any;
        cursor?: 'always' | 'motion' | 'never';
        displaySurface?: 'application' | 'browser' | 'monitor' | 'window';
        audio?: any;
    }
}

interface DisplayMediaStreamConstraints extends MediaTrackConstraints {
    video?: any;
    cursor?: 'always' | 'motion' | 'never';
    displaySurface?: 'application' | 'browser' | 'monitor' | 'window';
    audio?: any;
}

interface ExtendedMediaTrackConstraints extends MediaTrackConstraints {
    video?: any;
    cursor?: 'always' | 'motion' | 'never';
    displaySurface?: 'application' | 'browser' | 'monitor' | 'window';
    audio?: any;
}

interface Display {
    id: string;
    bounds: { x: number; y: number; width: number; height: number };
}

const RDHostApp: React.FC = () => {

    const history = useHistory()
    const [ friendemail, setfriendemail ] = useState("")

    const socketRef: any = useRef(null); // 🔹 Store socket connection
    const localsocketRef: any = useRef(null); // 🔹 Store socket connection
    const remoteVideoRef = useRef<any>(null);
    const peerConnection = useRef<any>(null);
    // const peerConnection = useRef<any>(new RTCPeerConnection({iceServers}));
    const dataChannelRef = useRef<any>(null)
    const [sharingCode, setSharingCode] = useState("");
    const [isSharing, setIsSharing] = useState(false);
    const screenStream = useRef<any>(null); // Store screen stream
    const [ message, setmessage ] = useState("")
    const [ displayWidth, setdisplayWidth ] = useState(0)
    const [ displayHeight, setdisplayHeight ] = useState(0)    

    const openModal = (h: any, m: any, p?: any)=>{
        setmessage(m)
    }

    const downloadpycode = async () => {
        var res = await fetchWrapper.getFile(`/api/downloadfile`);
        var blob = await res.blob() 
        download(blob, "local.py")
    }

    const connectToLocalServer = async () => {
        localsocketRef.current = io(`http://localhost:${process.env.REACT_APP_RD_PY_PORT}`);
    }

    const startSharing = async () => {
        try {

            const constraints: DisplayMediaStreamConstraints = {
                video: {
                  width: { max: 1920 },
                  height: { max: 1080 },
                  frameRate: { ideal: 30 },
                  cursor: 'always', // Now properly typed
                  displaySurface: 'monitor'
                } as ExtendedMediaTrackConstraints,
                audio: false
            }

            const stream: any = await navigator.mediaDevices.getDisplayMedia(constraints);
            screenStream.current = stream;

            const videoTrack = stream.getVideoTracks()[0];
            const settings = videoTrack.getSettings();
            setdisplayWidth(settings.width || 1920)
            setdisplayHeight(settings.height || 1080)
            
            peerConnection.current = new RTCPeerConnection({iceServers})
            peerConnection.current.addTrack(videoTrack, stream);
            
            // stream.getTracks().forEach((track) => {
            //     console.log('Adding track:', track);
            //     peerConnection.current.addTrack(track, stream);
            // });

            const newCallId = Math.random().toString(36).substring(2, 15);
            console.log(`socket endpoint : ${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`)
            
            socketRef.current = io(`${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_RD_PORT}`);

            connectToLocalServer()

            setSharingCode(newCallId);
            console.log(`emiting create-room: ${newCallId}`)
            videoTrack.addEventListener('ended', ()=>stopSharing(newCallId))

            peerConnection.current.onicecandidate = (event: any) => {
                if (event.candidate) {
                    console.log('Sending ICE candidate:', event.candidate);
                    socketRef.current.emit('candidate', { candidate: event.candidate, room: newCallId });
                }
            }

            dataChannelRef.current = peerConnection.current.createDataChannel("inputEvents", {
                ordered: true,       // Ensure event ordering
                reliable: true,
                maxRetransmits: 3,   // Balance between reliability and latency
                // maxPacketLifeTime: 1000 // For unreliable mode
                });

            dataChannelRef.current.onopen = handleremotedatachannelopen
            dataChannelRef.current.onclose = handleremotedatachannelclose

            dataChannelRef.current.onmessage = (event: any) => {    
                console.log(event.data)
                // const inputEvent = JSON.parse(event.data);
                handleControlMessage(event);
            }
            
            socketRef.current.on('room-joined', handleroomjoined)
            socketRef.current.on('answer', handleAnswer);
            // socketRef.current.on('remote-control', handleRemoteControl);
            socketRef.current.on('candidate', handleCandidate);
            socketRef.current.emit('create-room', newCallId);
            ///send shared window message to local server
            localsocketRef.current.emit('set_shared_window')
            setIsSharing(true);
        } catch (error) {
            console.log(`Error: ${error}`);
            setmessage(`${error}`)
        }
    }

    // Handle incoming control messages    
    const handleControlMessage = (event: MessageEvent) => {

        const msg = JSON.parse(event.data);
        switch(msg.type){
            case "mouse_move":
                localsocketRef.current.emit("mouse_move", {
                    type: msg.type,
                    x: msg.x * displayWidth,
                    y: msg.y * displayHeight,
                    buttons: msg.buttons,
                })
                break;
            case "mouse_down":
                console.log(`mouse_down : ${JSON.stringify(msg)}`)
                localsocketRef.current.emit("mouse_down", {
                    type: msg.type,
                    x: msg.x * displayWidth,
                    y: msg.y * displayHeight,
                    buttons: msg.buttons,
                })
                break;
            case "mouse_up":
                console.log(`mouse_up : ${JSON.stringify(msg)}`)
                // localsocketRef.current.emit("mouse_up", msg)
                break;
            case "mouse_click":
                console.log(`mouse_click : ${JSON.stringify(msg)}`)
                localsocketRef.current.emit("mouse_click", {
                    type: msg.type,
                    x: msg.x * displayWidth,
                    y: msg.y * displayHeight,
                    button: msg.button,
                    buttons: msg.buttons,
                })
                break;
            case "mouse_dblclick":
                console.log(`mouse_dblclick : ${JSON.stringify(msg)}`)
                localsocketRef.current.emit("mouse_dblclick", {
                    type: msg.type,
                    x: msg.x * displayWidth,
                    y: msg.y * displayHeight,
                    button: msg.button,
                })
                break;
            case "key_down":
                console.log(`key_down : ${JSON.stringify(msg)}`)
                localsocketRef.current.emit("key_down", msg)
                break;
            case "wheel":
                console.log(`mouse_wheel : ${JSON.stringify(msg)}`)
                localsocketRef.current.emit("mouse_wheel", {
                    type: "mouse_wheel",
                    x: msg.x * displayWidth,
                    y: msg.y * displayHeight,
                    deltaX: msg.deltaX,
                    deltaY: msg.deltaY,
                    deltaZ: msg.deltaZ,
                    deltaMode: msg.deltaMode,
                })
                break;
            default:
                break;
        }
    };

    const stopSharing = (room: any) => {
        setmessage("")
        screenStream.current?.getTracks().forEach((track: any) => track.stop());
        setIsSharing(false);
        setSharingCode('');
        socketRef.current.emit("end-sharing", {room: room})
        socketRef.current.disconnect();
        peerConnection.current.close()
        localsocketRef.current.disconnect()
    }
        
    const handleremotedatachannelopen = (data: any)=>{
        console.log(`remote datachannel is open: ${JSON.stringify(data)}`)
    }

    const handleremotedatachannelclose = (data: any)=>{
        console.log(`remote datachannel is closed: ${JSON.stringify(data)}`)
    }

    const handleroomjoined = async (data: any) => {
        console.log(`room-joined msg received!: ${JSON.stringify(data.room)}`)
        const offer = await peerConnection.current.createOffer();
        await peerConnection.current.setLocalDescription(offer);
        console.log('Sending Offer to room: ', data.room);
        socketRef.current.emit('offer', { offer: offer, room: data.room });
    }

    const handleAnswer = async ( data: any ) => {
        console.log('Received Answer:', data.answer);
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data.answer));
        socketRef.current.emit("answer-processed", {room: data.room})
    }

    const handleCandidate = async ( data: any ) => {
        console.log('Received ICE Candidate:', data.candidate);
        await peerConnection.current.addIceCandidate(new RTCIceCandidate(data.candidate));
    }

    const sharecode = async () => {
        if(!validateEmail(friendemail))
        {
            openModal("Warning", "Invalid email")
            return
        }
        await fetchWrapper.post("/api/invitefriend/sharerdcode", {friendemail:friendemail, callid: sharingCode})
        openModal("Message", `code shared with ${friendemail}`, false)
    }

    useEffect(() => {
        return () => {
            if(socketRef.current){
                socketRef.current.off('answer', handleAnswer);
                socketRef.current.off('candidate', handleCandidate);
                socketRef.current.disconnect();
            }
        }
    }, []);

    return(
        <MainContainer>
            <Header>Remote Desktop APP</Header>
            <Column>
            <Row style={{color:'red'}}>
                {message}
            </Row>
            {!isSharing ? (
                <ButtonContainer>
                    <Button handleClick={startSharing} text="Start Sharing" />
                    <Button handleClick={downloadpycode} text="Download Control Script" />
                </ButtonContainer>
            ) : (
                <>
                <InputComp id="call id" placeholder="share this call id" value={sharingCode} onChange={()=>{}} />
                <InputComp id="email" placeholder="participant email" value={friendemail} onChange={(e)=>setfriendemail(e.target.value)} />
                <ButtonContainer>
                    <Button handleClick={()=>stopSharing(sharingCode)} text="End Sharing" />
                    <Button text="Email Code" handleClick={sharecode} />
                </ButtonContainer>
                </>
            )}
            </Column>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const ButtonContainer = styled.div`
    display: flex;
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
    display: flex;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;

export default RDHostApp